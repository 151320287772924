import { withRouter } from 'react-router';
import { IRootState } from '../store';
import {
    onSortEngineers,
    loadEngineer,
    toggleCategory,
    loadEngineers,
    createEngineer,
    onChangeStatus,
    clearEngineer,
    updateEngineer,
    deleteEngineer,
} from 'actions/actions-v2/engineer-action-v2';
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';
import { Engineer } from './engineer-page';
import { IEngineerSkill, IEngineersFilters, IEngineer } from 'models';
import { AppActions } from 'actions/app-actions';
import { getPossibleEngineersForSite } from 'sharedSelectors';
import { getSiteQualificationCards } from 'actions/actions-v2';
import { loadGlobalSkillCards } from 'actions/global-skill-card-actions';

const mapStateToProps = (state: IRootState) => {
    return {
        isExportingEngineer: state.engineerState.isExportingEngineer,
        engineer: state.engineerState.engineer,
        isLoading: state.engineerState.isLoading,
        siteId: state.app.siteId,
        sites: state.app.lookupSites,
        isSaving: state.engineersState.isSaving,
        expandedCategories: state.engineerState.expandedCategories,
        engineers: state.engineersState.engineers,
        skillCategories: state.engineersState.skillCategories,
        users: getPossibleEngineersForSite(state),
        qualificationCards: state.skillsState.qualificationCards,
        globalSkillCards: state.adminCdnaGlobalState.globalSkillCards
    };
};

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        loadEngineer(id: string) {
            dispatch(loadEngineer(id));
        },
        loadEngineers(filters: IEngineersFilters) {
            dispatch(loadEngineers(filters));
        },
        onSort(key: string, sortAscending: boolean) {
            dispatch(onSortEngineers(key, sortAscending));
        },
        onToggleCategory(id: string) {
            dispatch(toggleCategory(id));
        },
        onChangeStatus(skill: IEngineerSkill) {
            dispatch(onChangeStatus(skill));
        },
        onUpdateEngineer(engineer: IEngineer) {
            dispatch(updateEngineer(engineer));
        },
        onArchiveEngineer(id: string) {
            dispatch(deleteEngineer(id));
        },
        loadUsers(siteId: string) {
            dispatch({
                type: AppActions.CHANGE_SITE,
                payload: {
                    siteId,
                },
            });
            if (!!siteId) {
                dispatch(getSiteQualificationCards(siteId));
            }
        },
        clearEngineer() {
            dispatch(clearEngineer());
        },
        /*  loadSkillCategories(siteId) {
            dispatch(loadSkillCategory(siteId));
        }, */
        createEngineer(engineer: IEngineer) {
            dispatch(createEngineer(engineer));
        },
        loadGlobalSkillCards(query) {
            dispatch(loadGlobalSkillCards(query));
        },
    };
};

export const EngineerContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Engineer));
