import * as React from 'react';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { IDifference } from 'models/difference';
import EntityHistory from 'components/history/entity-history';
import { Loader } from 'components/loader';
import { ISiteProfile } from 'models/site-profile';
import useTranslate from 'translations/translation-utils';

interface IProps {
    id: string;
    loadHistory: (id: string, operatingPlatform: string) => void;
    history: IDifference[][];
    isLoading: boolean;
    isLoadingHistory?: boolean;
    site: ISiteProfile;
}

const CermAssessmentHistoryTab: React.FC<IProps> = ({ id, loadHistory, history, isLoadingHistory, site }) => {
    const translate = useTranslate();
    React.useEffect(() => {
        if (!id) {
            return;
        }
        loadHistory(id, site.operatingPlatform);
    }, [id, loadHistory]);
    return (
        <Loader loading={isLoadingHistory}>
            <div className="tabs-container tab-content">
                <div className="section">
                    <div className="column is-12">
                        <div className="title is-4 section-title">{translate('RiskRegisterUpsertPage.Tabs.History')}</div>
                        <EntityHistory entityHistory={history} />
                    </div>
                </div>
            </div>
        </Loader>
    );
};
export default connect(
    selectors,
    actions
)(CermAssessmentHistoryTab);
