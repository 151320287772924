import { IAppAction } from './../app-action';
import { ISkillCategory } from 'models';
// import { ISkillCategoryFilters } from 'models/skillCategory-filters';

export enum SkillCategoryActions {
    LOAD_SKILL_CATEGORIES = '@@skillCategory/LOAD_SKILL_CATEGORIES',
    LOAD_SKILL_CATEGORIES_FULFILLED = '@@skillCategory/LOAD_SKILL_CATEGORIES_FULFILLED',
    LOAD_SKILL_CATEGORIES_REJECTED = '@@skillCategory/LOAD_SKILL_CATEGORIES_REJECTED',

    LOAD_SKILL_CATEGORY = '@@skillCategory/LOAD_SKILL_CATEGORY',
    LOAD_SKILL_CATEGORY_FULFILLED = '@@skillCategory/LOAD_SKILL_CATEGORY_FULFILLED',
    LOAD_SKILL_CATEGORY_REJECTED = '@@skillCategory/LOAD_SKILL_CATEGORY_REJECTED',

    CREATE_SKILL_CATEGORY = '@@skillCategory/CREATE_SKILL_CATEGORY',
    CREATE_SKILL_CATEGORY_FULFILLED = '@@skillCategory/CREATE_SKILL_CATEGORY_FULFILLED',
    CREATE_SKILL_CATEGORY_REJECTED = '@@skillCategory/CREATE_SKILL_CATEGORY_REJECTED',

    UPDATE_SKILL_CATEGORY = '@@skillCategory/UPDATE_SKILL_CATEGORY',
    UPDATE_SKILL_CATEGORY_FULFILLED = '@@skillCategory/UPDATE_SKILL_CATEGORY_FULFILLED',
    UPDATE_SKILL_CATEGORY_REJECTED = '@@skillCategory/UPDATE_SKILL_CATEGORY_REJECTED',

    SORT_SKILL_CATEGORIES = '@@skillCategory/SORT_SKILL_CATEGORIES',

    SITE_QUALIFICATION_CARDS = '@@skillCategory/SITE_QUALIFICATION_CARDS',
    SITE_QUALIFICATION_CARDS_FULFILLED = '@@skillCategory/SITE_QUALIFICATION_CARDS_FULFILLED',
    SITE_QUALIFICATION_CARDS_REJECTED = '@@skillCategory/SITE_QUALIFICATION_CARDS_REJECTED',
}

export const loadSkillCategories = (): IAppAction => ({
    type: SkillCategoryActions.LOAD_SKILL_CATEGORIES,
});

export const loadSkillCategory = (siteId: string, qualificationCardTypes: string): IAppAction => ({
    type: SkillCategoryActions.LOAD_SKILL_CATEGORY,
    payload: { siteId, qualificationCardTypes },
});

export const createSkillCategory = (skillCategory: ISkillCategory): IAppAction => ({
    type: SkillCategoryActions.CREATE_SKILL_CATEGORY,
    payload: skillCategory,
});

export const updateSkillCategory = (skillCategory: ISkillCategory): IAppAction => ({
    type: SkillCategoryActions.UPDATE_SKILL_CATEGORY,
    payload: skillCategory,
});

export const onSortSkillCategories = (
    key: Extract<keyof ISkillCategory, string>,
    sortAscending: boolean
) => ({
    type: SkillCategoryActions.SORT_SKILL_CATEGORIES,
    payload: { key, sortAscending },
});

export const getSiteQualificationCards = (siteId: string): IAppAction => ({
    type: SkillCategoryActions.SITE_QUALIFICATION_CARDS,
    payload: siteId,
});
