import * as React from 'react';
import { IKeyValue } from 'models';
import Page from 'components/v2/page/Page';
import { Button, ConfirmDialog, OverlayPanel, Panel, TabGroup, TabItem, Table } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { RouteComponentProps } from 'react-router';
import { Icon, IconType } from 'components/icon';
import { CompetencyDnaProcessAdminToolbar } from '../../components/competency-dna/competency-dna-process-admin-toolbar';
import Select from 'react-select';
import { FilterSet } from 'components/filterSet';
import './competency-dna-process-admin-page.scss';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { CompetencyDnaSkillCardDialogue } from 'components/competency-dna/competency-dna-skill-card-dialogue';
import { ICommonLookupModel } from 'models/common-lookup-model';
import _ from 'lodash';
import { GlobalSkillCategoryDialogue } from '../../components/competency-dna/global-skill-category-dialogue';
import { ISkillCard, IVisibility, getVisibility } from 'models/competency-dna/skill-card';
import { IGlobalSkillCategory, IGlobalSkillCategoryQuery } from 'models/competency-dna/cdna';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IGlobalSkillCardModel, IHierarchicalPathsModel } from 'models/admin-cdna/global-skill-card-model';
import { IGlobalSkillCardQuery } from 'models/admin-cdna/global-skill-card-query';
import { history } from 'routes/App';
import { Loader } from 'components/loader';
import { GlobalSkillDialog } from 'components/competency-dna/global-skill-dialog';
import { IGlobalSkill } from 'models/admin-cdna/global-skill';
import appConfig from 'helpers/config-helper';

interface IParams {
    skillCardId: string;
}

export interface ICompetencyDNAProcessProps extends RouteComponentProps<IParams> {
    lookupLineOfBusiness: ICommonLookupModel[];
    lookupCountryRegion: Array<IKeyValue<string>>;
    regions: Array<IKeyValue<string>>;
    operatingPlatforms: IOperatingPlatform[];
    lookupCountry: Array<IKeyValue<string>>;
    isLoading: boolean;
    isGlobalSkillCardQuery: IGlobalSkillCardQuery;
    isGlobalSkillCategoryQuery: IGlobalSkillCategoryQuery;
    permissions: IKeyValue<string>[];
    personProfile: any;
    lookupEnterpriseDivisions: ICommonLookupModel[];
    lookupLocalDivisions: ICommonLookupModel[];
    lookupDcsDivisions: ICommonLookupModel[];
    globalSkillCards: IGlobalSkillCardModel[];
    globalSkillCategories: IGlobalSkillCategory[];
    globalSkills: IGlobalSkill[];
    createGlobalSkillCard: (skillCard: ISkillCard) => void;
    loadGlobalSkillCards: (query: IGlobalSkillCardQuery) => void;
    updateGlobalSkillCard: (skillCard: ISkillCard) => void;
    loadGlobalSkillCategories: (query: IGlobalSkillCategoryQuery) => void;
    createGlobalSkillCategory: (globalSkillCategory: IGlobalSkillCategory) => void;
    updateGlobalSkillCategory: (globalSkillCategory: IGlobalSkillCategory) => void;
    deleteGlobalSkillCategory: (globalSkillCategoryId: string, skillCardId: string) => void;
    deleteGlobalSkillCard: (id: string) => void;
    createGlobalSkill: (globalSkill: IGlobalSkill) => void;
    loadGlobalSkillsByCategory: (categoryId: string) => void;
    loadGlobalSkillsByCard: (cardId: string) => void;
    deleteGlobalSkill: (categoryId: string, skillId: string) => void;
    updateGlobalSkill: (globalSkill: IGlobalSkill) => void;
    publishSkillCard: (skillCardId: string) => void;
    loadGlobalSkillCardsHierarchyPathsByVisiblity: (query: IGlobalSkillCardQuery) => void;
    globalSkillCardsHierarchyPathsByVisiblity: IHierarchicalPathsModel[];
    ResetGlobalSkillCardsHierarchyPathsByVisiblity: () => void;
}

export const CompetencyDNAProcessAdminPage: React.FC<ICompetencyDNAProcessProps> = props => {
    const initFilter: IVisibility = {
        operatingPlatforms: [],
        linesOfBusiness: [],
        regions: [],
        countries: []
    };
    const translate = useTranslate();
    const [showFilters, setShowFilters] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState<IVisibility>(initFilter);
    const [showQualificationCardDialogue, setShowQualificationCardDialogue] = React.useState<boolean>(false);
    const [showAddGlobalSkillCategoryDialogue, setShowAddGlobalSkillCategoryDialogue] = React.useState<boolean>(false);
    const [showEditGlobalSkillCategoryDialogue, setShowEditGlobalSkillCategoryDialogue] = React.useState<boolean>(false);
    const [showDeleteGlobalSkillCategoryDialogue, setShowDeleteGlobalSkillCategoryDialogue] = React.useState<boolean>(false);
    const [globalSkillCategoryToEdit, setGlobalSkillCategoryToEdit] = React.useState<IGlobalSkillCategory>(null);
    const [globalSkillCardTagData, setGlobalSkillCardTagData] = React.useState([]);
    const [isEditingGlobalSkillCards, setIsEditingGlobalSkillCards] = React.useState<boolean>(false);
    const [showDeleteGlobalSkillCardDialogue, setShowDeleteGlobalSkillCardDialogue] = React.useState<boolean>(false);
    const currentSelectedGlobalSkillCard = props.globalSkillCards?.find(x => x.id === props.match.params.skillCardId);
    const selectedSkillCardId = props.match.params.skillCardId;
    const [showGlobalSkillDialog, setShowGlobalSkillDialog] = React.useState<boolean>(false);
    const [showDeleteGlobalSkillDialogue, setShowDeleteGlobalSkillDialogue] = React.useState<boolean>(false);
    const [currentSelectedGlobalSkill, setCurrentSelectedGlobalSkill] = React.useState<IGlobalSkill>(null);
    const [showPublishSkillCardDialogue, setShowPublishSkillCardDialogue] = React.useState<boolean>(false);
    const changeSkillCardIdRoute = (skillCardId: string) => {
        history.push(props.match.path.replace(':skillCardId?', skillCardId));
    };
    const config = appConfig();

    React.useEffect(() => {
        if (
            (
                !selectedSkillCardId ||
                !currentSelectedGlobalSkillCard
            ) &&
            props.globalSkillCards.length > 0
        ) {
            changeSkillCardIdRoute(props.globalSkillCards[0].id);
        }

    }, [props.globalSkillCards, selectedSkillCardId]);

    React.useEffect(() => {
        props.loadGlobalSkillCards({
            operatingPlatforms: filters.operatingPlatforms,
            linesOfBusiness: filters.linesOfBusiness,
            regions: filters.regions,
            countries: filters.countries,
        });
    }, [filters]);

    React.useEffect(() => {
        if (!!selectedSkillCardId) {
            props.loadGlobalSkillCategories({
                skillCardId: selectedSkillCardId,
            } as IGlobalSkillCategoryQuery);
            props.loadGlobalSkillsByCard(selectedSkillCardId);
        }
    }, [selectedSkillCardId]);

    React.useEffect(() => {
        props.ResetGlobalSkillCardsHierarchyPathsByVisiblity();
        if (currentSelectedGlobalSkillCard && currentSelectedGlobalSkillCard?.visibility?.operatingPlatforms) {
            props.loadGlobalSkillCardsHierarchyPathsByVisiblity(currentSelectedGlobalSkillCard.visibility);
        }

    }, [currentSelectedGlobalSkillCard]);

    React.useEffect(() => {
        if (props.globalSkillCardsHierarchyPathsByVisiblity && props.globalSkillCardsHierarchyPathsByVisiblity.length === 0) {
            setGlobalSkillCardTagData([])
        }
        const resultHierarchicalPathsVisibility = props.globalSkillCardsHierarchyPathsByVisiblity && filterAndConcatenateTagData(props.globalSkillCardsHierarchyPathsByVisiblity);
        resultHierarchicalPathsVisibility && resultHierarchicalPathsVisibility.length > 0 && setGlobalSkillCardTagData(resultHierarchicalPathsVisibility)
    }, [props?.globalSkillCardsHierarchyPathsByVisiblity]);

    const handleInputChange = (value: Array<IKeyValue<string>>, key: string) => {
        setFilters(getVisibility(value, key, { ...filters }, true));
    };

    const onClearFilters = () => {
        setFilters(initFilter);
    };

    const deleteGlobalSkill = (globalSkill: any) => {
        setCurrentSelectedGlobalSkill(globalSkill);
        setShowDeleteGlobalSkillDialogue(true);
    }

    const addGlobalSkill = (categoryId: string) => {
        const globalSkill: IGlobalSkill = {
            id: null,
            skillCategoryId: categoryId,
            description: null,
            skillLevel: null
        };

        setCurrentSelectedGlobalSkill(globalSkill);
        setShowGlobalSkillDialog(true);
    }

    const editGlobalSkill = (globalSkill: any) => {
        setCurrentSelectedGlobalSkill(globalSkill);
        setShowGlobalSkillDialog(true);
    }

    const editGlobalQualificationCard = () => {
        setIsEditingGlobalSkillCards(true);
        setShowQualificationCardDialogue(true);
    }

    const editGlobalSkillCategory = (globalSkillCategory: IGlobalSkillCategory) => {
        if (globalSkillCategory) {
            setGlobalSkillCategoryToEdit(globalSkillCategory);
            setShowEditGlobalSkillCategoryDialogue(true);
        }
        else {
            setGlobalSkillCategoryToEdit(null);
            setShowEditGlobalSkillCategoryDialogue(false);
        }
    }

    const deleteGlobalSkillCategory = (globalSkillCategory: IGlobalSkillCategory) => {
        setGlobalSkillCategoryToEdit(globalSkillCategory);
        setShowDeleteGlobalSkillCategoryDialogue(true);
    }

    function filterAndConcatenateTagData(hierarchicalPathsData) {
        return hierarchicalPathsData && hierarchicalPathsData.length > 0 && hierarchicalPathsData.map((data => {
            let result;
            if (data?.operatingPlatform !== null) {
                result = `${data.operatingPlatform}`;
            }
            if (data?.lineOfBusiness !== null) {
                result = `${result}${' - ' + data.lineOfBusiness}`;
            }
            if (data?.region !== null) {
                result = `${result}${' - ' + data.region}`;
            }
            if (data?.country !== null) {
                result = `${result}${' - ' + data.country}`;
            }
            return result;
        }));
    }

    const getIsFilter = () => {
        return filters.operatingPlatforms.length > 0
            || filters.linesOfBusiness.length > 0
            || filters.regions.length > 0
            || filters.countries.length > 0;
    }

    const handleSaveGlobalSkillCard = (skillCard: any) => {
        if (skillCard.id && skillCard.id.length > 0) {
            props.updateGlobalSkillCard(skillCard);
        } else {
            props.createGlobalSkillCard(skillCard);
        }

        onQualificationCardDialogClose();
    };

    const setGlobalSkillCardVisibleToEngineers = () => {
        if (currentSelectedGlobalSkillCard && currentSelectedGlobalSkillCard.id) {
            props.publishSkillCard(currentSelectedGlobalSkillCard.id);
        }
        setShowPublishSkillCardDialogue(false);
    };

    const onQualificationCardDialogClose = () => {
        setShowQualificationCardDialogue(false);
        setIsEditingGlobalSkillCards(false);
    };

    const getBody = (translationId: string) => {
        return (
            <div className="cerm-process-step-platform-dialog">
                <p>{translate(translationId)}</p>
            </div>
        )
    };

    const handleDeleteGlobalQualificationCard = () => {
        props.deleteGlobalSkillCard(
            currentSelectedGlobalSkillCard && currentSelectedGlobalSkillCard.id);
        setShowDeleteGlobalSkillCardDialogue(false);
    };

    const handleDeleteGlobalSkill = () => {
        props.deleteGlobalSkill(
            currentSelectedGlobalSkill.skillCategoryId, currentSelectedGlobalSkill.id);
        setShowDeleteGlobalSkillDialogue(false);
    };

    const getEditDeleteCategoryActionItems = (category: any) => (
        <>
            <span
                className='accordion-icons edit-icon'
                onClick={() => editGlobalSkillCategory(category)}>
                <MaterialIcons
                    type={MaterialIconType.Edit}
                    color={MaterialIconColor.green}
                    isDisabled={!checkPermissions([Privilege.CompetencyDNAProcessAdminUpdate], props.permissions)}
                />
            </span>
            <span className='accordion-icons delete-icon' onClick={() => deleteGlobalSkillCategory(category)}>
                <MaterialIcons
                    type={MaterialIconType.Delete}
                    color={MaterialIconColor.grey}
                    isDisabled={!checkPermissions([Privilege.CompetencyDNAProcessAdminDelete], props.permissions)}
                />
            </span>
            <span className='accordion-icons add-competency-btn'>
                <Button
                    onClick={() => addGlobalSkill(category?.id)}>
                    {translate('CompetencyDNAProcessesAdmin.GlobalSkill.Button.AddCompetency')}
                </Button>
            </span>
        </>
    );

    const validateIfCategoryHaveSkills = (globalSkills: any, categoryId: string): boolean => {
        return globalSkills.filter(skill => skill.skillCategoryId == categoryId)?.length > 0 ? true : false;
    }

    return (
        <Page title={translate('SideNavBar.Labels.GlobalCompetencyDNAProcesses')} isAdminPage={true}>
            <div className='info-icon'>
                <Icon type={IconType.InfoCircle} />
                {translate('CDNAProcess.Information')}
            </div>

            <div>
                <div className='create-btn'>
                    <Button
                        id="createQualificationCardButton"
                        onClick={() => setShowQualificationCardDialogue(true)}
                        disabled={!checkPermissions([Privilege.CompetencyDNAProcessAdminCreate], props.permissions) || props.isLoading}
                        className="button is-primary is-fullwidth is-medium">
                        {translate('CompetencyDNAProcessesAdmin.QualificationCard.Label.Create')}
                    </Button>
                </div>

                <CompetencyDnaProcessAdminToolbar
                    isFilterExists={getIsFilter()}
                    onShowFilters={() => setShowFilters(true)}
                    onClearFilters={onClearFilters}
                    isLoading={props.isLoading}
                />
            </div>

            <Loader loading={props.isLoading} >
                <OverlayPanel
                    title={translate('CompetencyDNAProcessesAdmin.Filter.Label.Title')}
                    isVisible={showFilters}
                    onClose={() => setShowFilters(false)}
                    onOutsideDialogClick={() => setShowFilters(false)}
                    onClearFilters={onClearFilters}
                    isFilterExists={true}
                >
                    <FilterSet title={translate("Globals.Label.OperatingPlatform")} fieldName={"operatingPlatform"} >
                        <Select
                            id="operatingPlatform"
                            options={props.operatingPlatforms &&
                                props.operatingPlatforms.map((x) => ({
                                    value: x.id,
                                    label: x.value,
                                }))
                            }
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'operatingPlatform')}
                            placeholder={translate('Globals.Label.Select')}
                            value={filters && props.operatingPlatforms.filter(f => filters.operatingPlatforms.includes(f.id))
                                .map((x) => { return { label: x.value, value: x.id } })}
                        />
                    </FilterSet>

                    <FilterSet title={translate("Globals.Label.LineOfBusiness")} fieldName={"lineOfBusiness"} >
                        <Select
                            id="lineOfBusiness"
                            options={props.lookupLineOfBusiness &&
                                props.lookupLineOfBusiness.map((x: ICommonLookupModel) => ({
                                    value: x.key,
                                    label: `${x.operatingPlatform} - ${x.value}`,
                                }))
                            }
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'lineOfBusiness')}
                            placeholder={translate('Globals.Label.Select')}
                            value={filters && props.lookupLineOfBusiness.filter(f => filters.linesOfBusiness.includes(f.key))
                                .map((x) => {
                                    return {
                                        label: `${x.operatingPlatform} - ${x.value}`,
                                        value: x.key,
                                    }
                                })}
                        />
                    </FilterSet>

                    <FilterSet title={translate("Globals.Label.Regions")} fieldName={"regions"} >
                        <Select
                            id="regions"
                            options={props.regions && props.regions.map((x) => ({
                                value: x.key,
                                label: x.value,
                            }))}
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'regions')}
                            placeholder={translate('Globals.Label.Select')}
                            value={props.regions
                                .filter(f => filters.regions.includes(f.key))
                                .map((x) => { return { label: x.value, value: x.key } })}
                        />
                    </FilterSet>

                    <FilterSet title={translate("LinkedBuildings.Country")} fieldName={"country"} >
                        <Select
                            id="country"
                            options={props.lookupCountry.map((x) => ({
                                value: x.key,
                                label: x.label,
                            }))}
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'country')}
                            placeholder={translate('Globals.Label.Select')}
                            value={props.lookupCountry
                                .filter(x => filters.countries.includes(x.key))
                                .map((x) => { return { label: x.label, value: x.key } }
                                )
                            }
                        />
                    </FilterSet>
                </OverlayPanel>

                {showQualificationCardDialogue &&
                    <CompetencyDnaSkillCardDialogue
                        showDialogue={showQualificationCardDialogue}
                        onClose={onQualificationCardDialogClose}
                        operatingPlatforms={props.operatingPlatforms.filter(item => props.personProfile.accessRights.operatingPlatforms.includes(item.key))}
                        linesOfBusiness={props.lookupLineOfBusiness}
                        lookupCountryRegion={props.lookupCountryRegion}
                        lookupCountry={props.lookupCountry}
                        regions={props.regions}
                        onSave={(skillCard) => handleSaveGlobalSkillCard(skillCard)}
                        globalSkillCard={isEditingGlobalSkillCards ? currentSelectedGlobalSkillCard : null}
                        isLoading={props.isLoading}
                    />
                }

                {showDeleteGlobalSkillCardDialogue &&
                    <ConfirmDialog
                        title={translate('CompetencyDNAProcessesAdmin.QualificationCard.DeleteConfirmation.Title')}
                        message={getBody('CompetencyDNAProcessesAdmin.QualificationCard.DeleteConfirmation.BodyMessage')}
                        isVisible={showDeleteGlobalSkillCardDialogue}
                        onClose={() => setShowDeleteGlobalSkillCardDialogue(false)}
                        onOutsideDialogClick={() => setShowDeleteGlobalSkillCardDialogue(false)}
                        buttonCancelText={translate('Globals.Label.Cancel')}
                        onConfirm={() => handleDeleteGlobalQualificationCard()}
                        isLoading={props.isLoading}
                    />
                }

                {showDeleteGlobalSkillDialogue &&
                    <ConfirmDialog
                        title={translate('CompetencyDNAProcessesAdmin.GlobalSkill.DeleteConfirmation.Title')}
                        message={getBody('CompetencyDNAProcessesAdmin.GlobalSkill.DeleteConfirmation.BodyMessage')}
                        isVisible={showDeleteGlobalSkillDialogue}
                        onClose={() => setShowDeleteGlobalSkillDialogue(false)}
                        onOutsideDialogClick={() => setShowDeleteGlobalSkillDialogue(false)}
                        buttonCancelText={translate('Globals.Label.Cancel')}
                        onConfirm={() => handleDeleteGlobalSkill()}
                        isLoading={props.isLoading}
                    />
                }

                {showGlobalSkillDialog &&
                    <GlobalSkillDialog
                        skillCategoryId={currentSelectedGlobalSkill?.skillCategoryId}
                        isVisible={showGlobalSkillDialog}
                        isLoading={props.isLoading}
                        onCancel={() => setShowGlobalSkillDialog(false)}
                        onSave={(globalSkill) => {
                            if (globalSkill.id) {
                                props.updateGlobalSkill(globalSkill);
                            } else {
                                props.createGlobalSkill(globalSkill);
                            }
                            setShowGlobalSkillDialog(false);
                        }}
                        globalSkill={currentSelectedGlobalSkill}
                    />
                }

                <div className="container">
                    <div className='admin-cdna-tabs'>
                        <div className='columns'>
                            <div className='column is-11 no-padding-right'>
                                <div className={"" + (props.globalSkillCards && props.globalSkillCards.length < 9 ? 'pad-top' : '')}>
                                    <TabGroup>
                                        {props.globalSkillCards.map(x => {
                                            return (
                                                <TabItem
                                                    key={x.id}
                                                    title={x.name}
                                                    tab={x.id}
                                                    currentTab={selectedSkillCardId}
                                                    onClick={() => changeSkillCardIdRoute(x.id)}
                                                />
                                            );
                                        })}
                                    </TabGroup>
                                </div>
                            </div>
                            <div className='column is-1 no-padding-left'>
                                <span className='admin-tabs-side-icon' onClick={() => editGlobalQualificationCard()}>
                                    <MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.grey} />
                                </span>
                                <span className='admin-tabs-side-icon' onClick={() => setShowDeleteGlobalSkillCardDialogue(true)}>
                                    <MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.grey} />
                                </span>
                            </div>
                        </div>
                        <div className="admin-tabs-container">
                            {props.globalSkillCards.map((tab) => (
                                <div key={tab.id} className={`admin-tab-content ${tab.id === selectedSkillCardId ? 'is-active' : ''}`}>
                                    <div className="columns">
                                        <div className="column is-12">
                                            <div className="tag-box">
                                                <div className="tag-box-header">{translate('CompetencyDNAProcessesAdmin.QualificationCard.Label.Visibility')}</div>
                                                <div className="tags">
                                                    <Loader loading={globalSkillCardTagData && globalSkillCardTagData.length > 0 ? false : true}>
                                                        {globalSkillCardTagData && globalSkillCardTagData.length > 0 ? (
                                                            globalSkillCardTagData.map((data, index) => (
                                                                <span className="tag" key={index}>
                                                                    {data}
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <div className='no-data-available-tag'>
                                                                {translate('CompetencyDNAProcessesAdmin.QualificationCard.Error.NoTagAvailable')}
                                                            </div>
                                                        )}
                                                    </Loader>
                                                </div>
                                            </div>
                                            {showPublishSkillCardDialogue && <ConfirmDialog
                                                title={translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.PublishSkillCard')}
                                                message={getBody('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.PublishSkillCardDialogueBodyText')}
                                                isVisible={showPublishSkillCardDialogue}
                                                onClose={() => setShowPublishSkillCardDialogue(false)}
                                                onOutsideDialogClick={() => setShowPublishSkillCardDialogue(false)}
                                                buttonCancelText={translate('Globals.Label.Cancel')}
                                                onConfirm={() => setGlobalSkillCardVisibleToEngineers()}
                                                isLoading={props.isLoading}
                                            />}
                                            {showAddGlobalSkillCategoryDialogue && <GlobalSkillCategoryDialogue
                                                showDialogue={showAddGlobalSkillCategoryDialogue}
                                                isLoading={props.isLoading}
                                                skillCardId={tab.id}
                                                onCancel={() => setShowAddGlobalSkillCategoryDialogue(false)}
                                                saveGlobalSkillCategory={(globalSkillCategory) => {
                                                    props.createGlobalSkillCategory(globalSkillCategory);
                                                    setShowAddGlobalSkillCategoryDialogue(false);
                                                }}
                                            />}
                                            {showEditGlobalSkillCategoryDialogue && <GlobalSkillCategoryDialogue
                                                showDialogue={showEditGlobalSkillCategoryDialogue && !!globalSkillCategoryToEdit}
                                                isLoading={props.isLoading}
                                                skillCategory={globalSkillCategoryToEdit}
                                                skillCardId={globalSkillCategoryToEdit?.skillCardId}
                                                onCancel={() => editGlobalSkillCategory(null)}
                                                saveGlobalSkillCategory={(globalSkillCategory) => {
                                                    props.updateGlobalSkillCategory(globalSkillCategory);
                                                    editGlobalSkillCategory(null);
                                                }}
                                            />
                                            }
                                            {showDeleteGlobalSkillCategoryDialogue && <ConfirmDialog
                                                title={translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.DeleteConfirmation.Title')}
                                                message={translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.DeleteConfirmation.Body')}
                                                isVisible={showDeleteGlobalSkillCategoryDialogue}
                                                isLoading={props.isLoading}
                                                onClose={() => setShowDeleteGlobalSkillCategoryDialogue(false)}
                                                onConfirm={() => {
                                                    props.deleteGlobalSkillCategory(globalSkillCategoryToEdit.id, globalSkillCategoryToEdit.skillCardId);
                                                    setShowDeleteGlobalSkillCategoryDialogue(false);
                                                }}
                                            />}
                                        </div>
                                    </div>
                                    {config.REACT_APP_FEATURE_FLAG_PUBLISH_SKILL_CARD === 'true' ?
                                        <div className='columns'>
                                            {tab.publishedStatus === 'InProgress' && <div className='column is-10'><strong>{translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.PublishSkillCardDialogueInProgressMessage')}</strong></div>}
                                            {tab.publishedStatus === 'Published' && <div className='column is-10'><strong>{translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.PublishSkillCardDialogueConfirmMessage')}</strong></div>}
                                            {tab.publishedStatus === 'NotPublished' ? <>
                                                <div className='column is-10 info-publish-icon'>
                                                    <Button
                                                        className='make-visible-engineer-btn'
                                                        onClick={() => setShowPublishSkillCardDialogue(true)}
                                                        disabled={!checkPermissions([Privilege.CompetencyDNAProcessAdminCreate], props.permissions)}
                                                    >
                                                        {translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.PublishSkillCard')}
                                                    </Button>
                                                    <Icon type={IconType.InfoCircle} />
                                                    <div className='engineers-info-btn-text'>{translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.PublishSkillCardInfoBox')}</div>
                                                </div>

                                            </>
                                                : ''
                                            }
                                            <div className='column is-2 has-text-right'>
                                                <Button
                                                    className='add-category-btn'
                                                    onClick={() => setShowAddGlobalSkillCategoryDialogue(true)}
                                                    disabled={!checkPermissions([Privilege.CompetencyDNAProcessAdminCreate], props.permissions)}
                                                >
                                                    {translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.AddCategory')}
                                                </Button>
                                            </div>
                                        </div> :
                                        <div className='column is-12 has-text-right'>
                                            <Button
                                                className='add-category-btn'
                                                onClick={() => setShowAddGlobalSkillCategoryDialogue(true)}
                                                disabled={!checkPermissions([Privilege.CompetencyDNAProcessAdminCreate], props.permissions)}
                                            >
                                                {translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Button.AddCategory')}
                                            </Button>
                                        </div>
                                    }
                                    <div key={tab.id}>
                                        {props &&
                                            props?.globalSkillCategories &&
                                            props?.globalSkillCategories?.length > 0 ? (
                                            props?.globalSkillCategories?.map((category, categoryIndex) => (
                                                <div key={categoryIndex}>
                                                    <>
                                                        <Panel title={category.title}
                                                            intro={category.description}
                                                            bodyPadding={true}
                                                            collapsed={true}
                                                            componentId={category.title}
                                                            headerElements={getEditDeleteCategoryActionItems(category)}>
                                                            {props &&
                                                                props.globalSkills &&
                                                                props.globalSkills.length > 0 &&
                                                                validateIfCategoryHaveSkills(props.globalSkills, category.id) ? (
                                                                <Table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="narrow">{translate('CompetencyDNAProcessesAdmin.GlobalSkill.ColumnLabel.Level')}</th>
                                                                            <th>{translate('CompetencyDNAProcessesAdmin.GlobalSkill.ColumnLabel.SystemKnowledge')}</th>
                                                                            <th className="narrow"></th>
                                                                            <th className="narrow"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {props.globalSkills
                                                                            .filter(skill => skill.skillCategoryId === category.id)
                                                                            .map((skill, skillIndex) => (
                                                                                <tr key={skillIndex}>
                                                                                    <td>{skill.skillLevel}</td>
                                                                                    <td>
                                                                                        <span className="word-break whitespace-wrap">
                                                                                            {skill.description}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className='inline-icon' onClick={() => editGlobalSkill(skill)}>
                                                                                            <MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className='inline-icon' onClick={() => deleteGlobalSkill(skill)}>
                                                                                            <MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </Table>
                                                            ) : (
                                                                <Table className='no-cat-available'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <p>{translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Error.NoSkillsAvailable')}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            )}
                                                        </Panel>
                                                    </>
                                                </div>
                                            ))) : (
                                            <div className='no-data-available-cat'>
                                                {translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Error.NoSkillCategoryAvailable')}
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Loader>
        </Page>
    );
};