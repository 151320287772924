import { IRootState } from '../store';
import { loadEngineers, onSortEngineers } from 'actions/actions-v2/engineer-action-v2';
import { connect } from 'react-redux';
import { Engineers } from './engineers-page';
import { IEngineersFilters } from 'models';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';

const mapStateToProps = (state: IRootState) => {
    return {
        isExportingEngineers: state.engineersState.isExportingEngineers,
        engineers: state.engineersState.engineers,
        isLoading: state.engineersState.isLoading,
        lookupSites: state.app.lookupSites,
        siteId: state.app.siteId,
        currentPerson: state.person.personProfile,
        permissions: state.app.permissions
    };
};

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        loadEngineers(filters: IEngineersFilters) {
            dispatch(loadEngineers(filters));
        },
        onSort(key: string, sortAscending: boolean) {
            dispatch(onSortEngineers(key, sortAscending));
        },
    };
};

export const EngineersContainer = connect(mapStateToProps, mapDispatchToProps)(Engineers);
