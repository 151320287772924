import * as React from 'react';
import { GridSortLink } from 'components/gridSortLink';
import { SortDirection } from 'enums/SortDirection';
import { IEngineer, SkillLevel, IEngineersFilters, IKeyValue } from 'models';
import { onRouteChange } from 'actions/app-actions';
import './engineer.scss';
import { formatDate } from 'utils/date-utils';
import { getSkillLevel, getSkillLevelText, SkillLevelTextType } from 'business-area-functions/cdna';
import { IPerson } from 'models/person';
import { Privilege } from 'enums/Privilege';
import { checkPermissions } from 'utils/permission-utils';
import { translate } from 'translations/translation-utils';
import Dotdotdot from 'react-dotdotdot';

export interface IEngineersListProps {
    engineers: IEngineer[];
    filters: IEngineersFilters;
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    currentPerson: IPerson;
    permissions: Array<IKeyValue<string>>;
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    intl: any;
}

export class EngineersList extends React.Component<IEngineersListProps> {
    public openEngineer = (engineerId: string, qualificationCardTypes: string[]) =>
        onRouteChange(`/Engineer/${engineerId}/${qualificationCardTypes[0]}`);

    public render() {
        const props = this.props;

        return (
            <div className="EngineersList">
                {props.engineers && props.engineers.length > 0 ? (
                    <React.Fragment>
                        <div className={`grid-view`}>
                            <div
                                className={`grid-headers columns ${props.isLoading ? 'is-loading' : ''
                                    }`}
                            >
                                <div className="column is-2">
                                    {this.getGridHeader(translate(props.intl, 'Engineer.Overview.FirstName'), 'firstName', props)}
                                </div>
                                <div className="column is-2">
                                    {this.getGridHeader(translate(props.intl, 'Engineer.Overview.LastName'), 'lastName', props)}
                                </div>
                                <div className="column is-3">
                                    {this.getGridHeader(translate(props.intl, 'Engineer.Overview.Role'), 'roles', props)}
                                </div>
                                <div className="column is-2">
                                    {this.getGridHeader(translate(props.intl, 'Engineer.Overview.Updated'), 'lastUpdatedDateTime', props)}
                                </div>
                                <div className="column is-3">{translate(props.intl, 'Engineer.Overview.Status')}</div>
                            </div>
                            {props.engineers &&
                                props.engineers.length > 0 &&
                                props.engineers
                                    .filter(
                                        level =>
                                            (this.props.filters.levels &&
                                                this.props.filters.levels.length === 0) ||
                                            (this.props.filters.levels &&
                                                this.props.filters.levels.some(
                                                    y => y === getSkillLevel(level.skills)
                                                ))
                                    )
                                    .map(engineer =>
                                        this.getEngineerItem(
                                            engineer,
                                            getSkillLevel(engineer.skills)
                                        )
                                    )}
                        </div>
                    </React.Fragment>
                ) : (
                    <p> No Items Found</p>
                )}
            </div>
        );
    }

    private getEngineerItem = (engineer: IEngineer, skillLevel: SkillLevel | null) => (
        <div
            className={`grid-view__item is-hoverable`}
            id={'engineers-list-' + engineer.id.toString()}
            key={engineer.id}
            onClick={this.isAllowedToViewEngineer(engineer) ? this.openEngineer.bind(this, engineer.id, engineer.qualificationCardTypes) : null}
            style={this.isAllowedToViewEngineer(engineer) ? { cursor: 'pointer' } : null}
        >
            <div className="columns incidents-columns">
                <div className="column is-2">
                    <Dotdotdot clamp={1}><>{engineer && engineer.firstName}</></Dotdotdot>
                </div>
                <div className="column is-2">
                    <Dotdotdot clamp={1}><> {engineer && engineer.lastName}</></Dotdotdot>
                </div>
                <div className="column is-3">
                    <Dotdotdot clamp={1}>{engineer && engineer.roles.join(', ')}</Dotdotdot>
                </div>
                <div className="column is-2">
                    {engineer.skills.every(x => x.updatedDate === null)
                        ? '-'
                        : formatDate(
                            new Date(
                                Math.max.apply(
                                    null,
                                    engineer.skills.map(x => new Date(x.updatedDate))
                                )
                            ).toDateString()
                        )}
                </div>
                <div className="column is-2">
                    {getSkillLevelText(skillLevel, SkillLevelTextType.LevelAndCategory)}
                </div>
                <div className="column is-1">
                    <span
                        className={
                            skillLevel === SkillLevel.C
                                ? 'engineer-c'
                                : skillLevel === SkillLevel.B
                                    ? 'engineer-b'
                                    : skillLevel === SkillLevel.A
                                        ? 'engineer-a'
                                        : ''
                        }
                    >
                        {getSkillLevelText(skillLevel, SkillLevelTextType.Letter)}
                    </span>
                </div>
            </div>
        </div>
    );

    private getGridHeader = (label: string, fieldName: string, props: IEngineersListProps) => {
        const onSort = (sortField: string, sortDirection: SortDirection) =>
            props.onSort(sortField, sortDirection === SortDirection.Ascending);
        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={
                    props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                }
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    };

    private isAllowedToViewEngineer = (engineer: IEngineer) => {
        return checkPermissions([Privilege.CDNAUpdate], this.props.permissions)
            || (
                checkPermissions([Privilege.CDNAView], this.props.permissions)
                && engineer.userId === this.props.currentPerson.id
            );
    }
}
