import * as React from 'react';
import { IKeyValue, IEngineerSkill, ISkillCategory, ISkill, ISiteSkills } from 'models';
import { Loader } from 'components/loader';
import { CompetencyDNAProcesses } from 'components/engineer/competency-dna-processes';
import Page from 'components/v2/page/Page';
import { CompetencyDnaToolbar } from 'components/engineer/competency-dna-toolbar';
import { QualificationType } from 'models/engineer-enums';
import { ConfirmDialog, TabGroup, TabItem } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { getSiteOperatingPlatform } from 'models/site-profile';
import { RouteComponentProps } from 'react-router';
import { history } from 'routes/App';
import { Icon, IconType } from 'components/icon';
import { AddLocalSkillCategory } from 'components/engineer/add-local-skill-category-form';

interface IParams {
    qualificationCard?: string;
}

export interface ICompetencyDNAProcessProps extends RouteComponentProps<IParams> {
    globalSkills: ISkill[];
    siteSkills: ISiteSkills | null;
    isLoading: boolean;
    lookupLevels: Array<IKeyValue<string>>;
    lookupCategories: Array<IKeyValue<string>>;
    lookupSkillStatuses: Array<IKeyValue<string>>;
    onCloseFilterModal(): void;
    loadEngineerSkills(siteId: string, qualificationCardTypes: string): void;
    onSort: (key: string, sortAscending: boolean) => void;
    onToggleCategory: (id: string) => void;
    onChangeStatus: (skill: IEngineerSkill) => void;
    isExportingEngineerSkills: boolean;
    expandedCategories: string[];
    skillCategories: ISkillCategory[];
    siteName: string;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
    lookupSitesClient: Array<IKeyValue<string>>;
    onSaveSiteSkills: (siteSkills: ISiteSkills, hasNoSiteSkillsRecord: boolean) => void;
    addEngineerSkillCategory: (id: string) => void;
    resetExpandedCategories: () => void;
    loadSiteSkills: (siteId: string) => void;
    hasHideShowPermission: boolean;
    hasYesNoPermission: boolean;
    hasAccountSkillPermission: boolean;
    qualificationCards: string[];
}

export const CompetencyDNAProcessPage: React.FC<ICompetencyDNAProcessProps> = props => {
    const translate = useTranslate();
    const [showDialog, setShowDialog] = React.useState(false);
    const [showCreateQualificationCard, setShowCreateQualificationCard] = React.useState(false);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const siteOperatingPlatform = getSiteOperatingPlatform(props.siteId, props.lookupSites);
    const [sameClientSites, setSameClientSites] = React.useState<Array<IKeyValue<string>>>();
    const [siteQualificationCards, setSiteQualificationCards] = React.useState<string[]>(props.qualificationCards);
    const [routeToQualificationCard, setRouteToQualificationCard] = React.useState<string>(null);
    const selectedQualificationCard = props.match.params.qualificationCard;

    const getClientSitesByOperatingPlatform = (clientSites: any[]) => {
        return clientSites.filter(x => x.operatingPlatform === siteOperatingPlatform);
    };

    React.useEffect(() => {
        if (
            !selectedQualificationCard ||
            !siteQualificationCards.includes(selectedQualificationCard)
        ) {
            changeQualificationCardRoute(QualificationType.ME);
        }
    }, [props.qualificationCards, selectedQualificationCard]);

    React.useEffect(() => {
        setSiteQualificationCards(props.qualificationCards);
    }, [props.qualificationCards]);

    React.useEffect(() => {
        if (props.siteId) {
            props.resetExpandedCategories();
            props.loadSiteSkills(props.siteId);
        }
    }, [props.siteId]);

    React.useEffect(() => {
        if (props.lookupSitesClient && props.lookupSites && props.siteId) {
            const clientName = props.lookupSitesClient?.find(x => x.key === props.siteId)?.value;

            const clientSites = props.lookupSites?.filter(x =>
                props.lookupSitesClient?.findIndex(y =>
                    y.value === clientName && x.key === y.key
                ) >= 0
            );
            setSameClientSites(getClientSitesByOperatingPlatform(clientSites));
        } else {
            setSameClientSites(null);
        }
    }, [props.lookupSitesClient, props.lookupSites, props.siteId]);

    React.useEffect(() => {
        if (props.siteId) {
            props.loadEngineerSkills(props.siteId, selectedQualificationCard);
        }

        if (!!routeToQualificationCard) {
            changeQualificationCardRoute(routeToQualificationCard);
            setRouteToQualificationCard(null);
        }
    }, [props.siteSkills, selectedQualificationCard]);

    const addLocalSkillCategory = (showSkill?: boolean) => {
        const show = showSkill ? showSkill : !showDialog;
        setShowDialog(show);
    };

    const changeQualificationCardRoute = (qualificationCard: string) => {
        history.push(props.match.path.replace(':qualificationCard?', qualificationCard));
    };

    return (
        <Page title={translate('SideNavBar.Labels.CompetencyDNAProcesses')}>
            <div>
                <Icon type={IconType.InfoCircle} />
                {translate('CDNAProcess.Information')}
            </div>
            <CompetencyDnaToolbar addLocalSkillCategory={addLocalSkillCategory} />
            <TabGroup>
                {siteQualificationCards.map(x => {
                    return (
                        <TabItem
                            key={x}
                            title={x}
                            tab={x}
                            currentTab={selectedQualificationCard}
                            onClick={() => changeQualificationCardRoute(x)}
                        />
                    );
                })}
                <TabItem
                    title={(
                        <>
                            <strong>{translate('CDNAProcess.Create')}</strong>
                            <Icon type={IconType.PlusCircle} />
                        </>
                    )}
                    tab={'Create'}
                    currentTab={selectedQualificationCard}
                    onClick={() => setShowCreateQualificationCard(true)}
                />
            </TabGroup>
            {props.globalSkills ? (
                <Loader loading={props.isLoading}>
                    <CompetencyDNAProcesses
                        globalSkills={props.globalSkills}
                        isLoading={props.isLoading}
                        expandedCategories={props.expandedCategories}
                        onToggleCategory={props.onToggleCategory}
                        skillCategories={props.skillCategories}
                        siteSkills={props.siteSkills}
                        siteId={props.siteId}
                        sameClientSites={sameClientSites}
                        onSaveSiteSkills={props.onSaveSiteSkills}
                        showDialog={showDialog}
                        addLocalSkillCategory={addLocalSkillCategory}
                        addEngineerSkillCategory={props.addEngineerSkillCategory}
                        qualificationCardTypes={selectedQualificationCard}
                        skillCategeryHideshowPermission={props.hasHideShowPermission}
                        skillCategeryYesNoPermission={props.hasYesNoPermission}
                        showTabDisabledDialog={setShowTabDisabledDialog}
                        hasAccountSkillPermission={props.hasAccountSkillPermission}
                    />
                    <ConfirmDialog
                        title={translate('CermAssesmentTab.Title.Information')}
                        message={translate('CermAssesmentTab.Title.Message')}
                        buttonCancelHide={true}
                        buttonConfirmText={translate('CermAssesmentTab.Title.Ok')}
                        isVisible={showTabDisabledDialog}
                        onConfirm={() => setShowTabDisabledDialog(false)}
                        onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
                    />
                    {showCreateQualificationCard && (
                        <AddLocalSkillCategory
                            globalSkills={[]}
                            localSkills={[]}
                            onSaveLocalSkillCategory={(skill, skillCategory) => {
                                setSiteQualificationCards(
                                    siteQualificationCards.concat(
                                        skillCategory.qualificationCardType
                                    )
                                );
                                const siteSkills: ISiteSkills = {
                                    id: props.siteSkills?.id ?? props.siteId,
                                    turnedOffGlobalSkills: props.siteSkills?.turnedOffGlobalSkills ?? [],
                                    turnedOffGlobalSkillCategories:
                                        props.siteSkills?.turnedOffGlobalSkillCategories ?? [],
                                    localSkills: (props.siteSkills?.localSkills ?? []).concat(skill),
                                    localSkillCategories: (
                                        props.siteSkills?.localSkillCategories ?? []
                                    ).concat(skillCategory),
                                    applyToAccountLevelGlobalSkills: props.siteSkills?.applyToAccountLevelGlobalSkills ?? [],
                                };

                                props.onSaveSiteSkills(siteSkills, !props.siteSkills);
                                setShowCreateQualificationCard(false);
                                setRouteToQualificationCard(skillCategory.qualificationCardType);
                            }}
                            onCancel={() => setShowCreateQualificationCard(false)}
                            allSkillCategories={[]}
                            qualificationCardTypes={undefined}
                            siteId={props.siteId}
                            lookupSites={sameClientSites}
                        />
                    )}
                </Loader>
            ) : (
                ''
            )}
        </Page>
    );
};
