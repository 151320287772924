import { IRootState } from '../store';
import {
    toggleEngineerSkillCategory,
    addEngineerSkillCategory,
    resetExpandedCategories,
} from 'actions/actions-v2/engineer-action-v2';
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';
import { CompetencyDNAProcessPage } from './competency-dna-process-page';
import { IAppState } from 'reducers/app';
import { createSelector } from 'reselect';
import {
    loadSkills,
    loadSiteSkills,
    updateSiteSkills,
    createSiteSkills,
    ISiteSkillsFilters,
    loadSkillCategory,
    getSiteQualificationCards,
} from 'actions/actions-v2';
import { ISiteSkills } from 'models';
import { getPermissions } from 'sharedSelectors';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

export const getCurrentSiteName = createSelector(
    (state: IRootState) => state.app,
    (state: IAppState) => {
        const currentSiteId = state.siteId;
        const currentSite = currentSiteId
            ? state.lookupSites.find((m) => m.key === currentSiteId)
            : null;
        const currentSiteName: string = currentSite ? currentSite.value : '';
        return currentSiteName ? currentSiteName : '';
    }
);

const getSiteId = (state: IRootState) => state.app.siteId;
const getSkillState = (state: IRootState) => state.skillsState;
const getSkillCategories = (state: IRootState) => state.engineersState.skillCategories;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getLookupSitesClient = (state: IRootState) => state.app.lookupSitesClient;

const mapStateToProps = createSelector(
    getSkillState,
    getSkillCategories,
    getCurrentSiteName,
    getSiteId,
    getLookupSites,
    getLookupSitesClient,
    getPermissions,
    (
        skillsState,
        skillCategories,
        siteName,
        siteId,
        lookupSites,
        lookupSitesClient,
        permissions
    ) => ({
        isExportingEngineerSkills: skillsState.isExportingSkills,
        globalSkills: skillsState.skills,
        isLoading: skillsState.isLoading,
        isSaving: skillsState.isSaving,
        expandedCategories: skillsState.expandedCategories,
        skillCategories,
        siteName,
        siteId,
        lookupSites,
        lookupSitesClient,
        siteSkills: skillsState.siteSkills,
        hasHideShowPermission: checkPermissions(
            [Privilege.CompetencyDNACategorySkillHideShow],
            permissions
        ),
        hasYesNoPermission: checkPermissions([Privilege.CompetencyDNASkillYesNo], permissions),
        hasAccountSkillPermission: checkPermissions(
            [Privilege.CompetencyDNAAccountSkill],
            permissions
        ),
        qualificationCards: skillsState.qualificationCards,
    })
);

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        loadEngineerSkills(siteId: string, qualificationCardTypes: string) {
            dispatch(loadSkills(qualificationCardTypes));
            dispatch(loadSkillCategory(siteId, qualificationCardTypes));
            dispatch(getSiteQualificationCards(siteId));
        },
        loadSiteSkills(siteId: string) {
            const filters: ISiteSkillsFilters = { id: siteId };
            dispatch(loadSiteSkills(filters));
            dispatch(getSiteQualificationCards(siteId));
        },
        onToggleCategory(id: string) {
            dispatch(toggleEngineerSkillCategory(id));
        },
        onSaveSiteSkills(siteSkills: ISiteSkills, hasNoSiteSkillsRecord: boolean) {
            if (hasNoSiteSkillsRecord) {
                dispatch(createSiteSkills(siteSkills));
            } else {
                dispatch(updateSiteSkills(siteSkills));
            }
        },
        addEngineerSkillCategory(id: string) {
            dispatch(addEngineerSkillCategory(id));
        },
        resetExpandedCategories() {
            dispatch(resetExpandedCategories());
        },
    };
};

export const CompetencyDNAProcessContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetencyDNAProcessPage);
