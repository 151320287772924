import React from 'react';
import { IEngineer, IEngineersFilters, IKeyValue, ISkillCategory } from 'models';
import { Loader } from 'components/loader';
import { RouteComponentProps } from 'react-router';
import { Field, Control, Help } from 'components/form';
import Select from 'react-select';
import { BulmaSize } from 'enums/BulmaSize';
import { IPersonLookup } from 'models/person-lookup';
import { Button, Toolbar, ToolbarGroup, ToolbarGroupRight } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { IOption, SelectField } from 'components/form/fields/select-field';
import { Checkbox } from 'components/form/Checkbox';
import { TextField } from 'components/form/fields/text-field';
import { BulmaColor } from 'enums/BulmaColor';
import { onRouteChange } from 'actions/app-actions';
import _ from 'lodash';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';
import { IGlobalSkillCardQuery } from 'models/admin-cdna/global-skill-card-query';
import { IVisibility } from 'models/competency-dna/skill-card';
import { IGlobalSkillCardModel } from 'models/admin-cdna/global-skill-card-model';

export interface IEngineerProps extends RouteComponentProps<IParams> {
    engineer: IEngineer;
    engineers: IEngineer[];
    isLoading: boolean;
    isLoadingUsers: boolean;
    skillCategories: ISkillCategory[];
    siteId: string;
    users: IPersonLookup[];
    sites: Array<IKeyValue<string>>;
    isSaving: boolean;
    qualificationCards: string[];
    loadEngineer(id: string): void;
    clearEngineer(): void;
    loadUsers: (siteId: string) => void;
    loadEngineers(filters: IEngineersFilters): void;
    onUpdateEngineer: (engineer: IEngineer) => void;
    loadSkillCategories(siteid: string): void;
    createEngineer: (engineer: IEngineer) => void;
    onArchiveEngineer: (id: string) => void;
    loadGlobalSkillCards: (query: IGlobalSkillCardQuery) => void;
    globalSkillCards: IGlobalSkillCardModel[];
}

interface IParams {
    engineerId: string;
    tab: string;
    userId: string;
}

export const Engineer: React.FC<IEngineerProps> = ({
    engineer,
    engineers,
    isLoadingUsers,
    isLoading,
    isSaving,
    siteId,
    users,
    sites,
    qualificationCards,
    loadEngineer,
    clearEngineer,
    loadUsers,
    loadEngineers,
    loadSkillCategories,
    createEngineer,
    onUpdateEngineer,
    match,
    loadGlobalSkillCards,
    globalSkillCards,
}) => {
    const initFilter: IVisibility = {
        operatingPlatforms: [],
        linesOfBusiness: [],
        regions: [],
        countries: [],
    };
    const translate = useTranslate();
    const [user, setUser] = React.useState({} as IOption);
    const [engUpdated, setEngUpdated] = React.useState(false);
    const [selectedQualification, setSelectedQualification] = React.useState<string[]>([]);
    const [showWarning, setShowWarning] = React.useState(false);
    const engineersPageUrl = '/Engineers';
    const customLinks: Array<IKeyValue<string>> = [
        { key: engineersPageUrl, value: translate('SideNavBar.Labels.DCProfessionals') },
    ];

    React.useEffect(() => {
        loadUsers(siteId);
    }, [
        match.params.engineerId,
        match.params.userId,
        siteId,
        loadUsers,
        loadEngineer,
        loadSkillCategories,
        clearEngineer,
        loadEngineers,
    ]);

    React.useEffect(() => {
        if (!match.params.engineerId) {
            clearEngineer();
        }
        if (Object.keys(engineer).length === 0 && match.params.engineerId) {
            loadEngineer(match.params.engineerId);
        }

        loadGlobalSkillCards({
            operatingPlatforms: initFilter.operatingPlatforms,
            linesOfBusiness: initFilter.linesOfBusiness,
            regions: initFilter.regions,
            countries: initFilter.countries,
        });
    }, []);

    React.useEffect(() => {
        if (engUpdated && !isSaving) {
            onRouteChange(`/Engineer/${match.params.engineerId}/${selectedQualification[0]}`);
        }
    }, [isSaving]);

    React.useEffect(() => {
        let filteredDefaultGlobalSkilledCards = [];
        if (qualificationCards && qualificationCards.length > 0 && globalSkillCards.length > 0) {
            const filteredskillCards =
                qualificationCards &&
                qualificationCards.length > 0 &&
                globalSkillCards.length > 0 &&
                globalSkillCards.filter(el => {
                    return qualificationCards.some(f => {
                        return f.toLowerCase() === el.name.toLowerCase();
                    });
                });
            filteredskillCards &&
                filteredskillCards.length > 0 &&
                filteredskillCards.map(card => {
                    if (card?.publishedStatus === 'Published') {
                        filteredDefaultGlobalSkilledCards.push(card?.name.toUpperCase());
                    }
                });
        }
        if (engineer && engineer.qualificationCardTypes !== undefined && match.params.engineerId) {
            if (filteredDefaultGlobalSkilledCards && filteredDefaultGlobalSkilledCards.length > 0) {
                setSelectedQualification(
                    _.uniq([
                        ...engineer.qualificationCardTypes,
                        ...filteredDefaultGlobalSkilledCards,
                    ])
                );
            } else {
                setSelectedQualification(engineer.qualificationCardTypes);
            }
        }
        if (
            filteredDefaultGlobalSkilledCards &&
            filteredDefaultGlobalSkilledCards.length > 0 &&
            match.params.engineerId === undefined
        ) {
            setSelectedQualification(filteredDefaultGlobalSkilledCards);
        }
    }, [engineer, qualificationCards]);

    const selectUser = (e: any) => {
        if (!Array.isArray(e)) {
            const x = e as IOption;
            setUser(x);
        }
    };

    const selectedValue = () => {
        if (sites.length > 0) {
            const currentValue = sites.find(f => f.key === siteId);
            if (currentValue) {
                return { value: currentValue.key, label: currentValue.value };
            }
        }
        return null;
    };

    const onSiteChange = (newSiteId: string) => {
        loadUsers(newSiteId);
    };

    const engId = match.params.userId;
    const existingEngineerIds = engineers.map(c => c.userId);
    const newEngineers = users.filter(c => !existingEngineerIds.some(cc => cc === c.id));

    const onToggleCheckList = e => {
        const qualificationCardTypes = e.target.value;
        if (selectedQualification.some(s => s === qualificationCardTypes)) {
            setSelectedQualification(
                selectedQualification.filter(item => item !== qualificationCardTypes)
            );
            setShowWarning(true);
        } else {
            setSelectedQualification([...selectedQualification, qualificationCardTypes]);
            setShowWarning(false);
        }
    };

    const createNew = () => {
        const newEngineer = {
            userId: user.value,
            siteId,
            qualificationCardTypes: selectedQualification,
        } as IEngineer;
        createEngineer(newEngineer);
    };

    const updateEngineer = () => {
        const eng = _.cloneDeep(engineer);
        eng.qualificationCardTypes = selectedQualification;
        onUpdateEngineer(eng);
        setEngUpdated(true);
    };

    const onCancel = () => {
        history.push('/Engineers');
    };

    return (
        <Page
            title={translate('SideNavBar.Labels.CompetencyDNA')}
            className="engineer-page create-engineer-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={engineersPageUrl}
            breadcrumbCustomLinks={customLinks}
        >
            <Loader loading={isLoading}>
                <div className="tabs-container tab-content">
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <Field
                                className="is-medium"
                                isHorizontal={true}
                                htmlFor=""
                                label={translate('EngineerPage.Lable.1')}
                                labelSize={BulmaSize.Medium}
                                infoText=""
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="all-sites-selector"
                                            value={selectedValue()}
                                            onChange={(value: any) => {
                                                onSiteChange(value.value);
                                            }}
                                            options={sites.map(x => ({
                                                value: x.key,
                                                label: x.value,
                                            }))}
                                            isDisabled={!engId ? false : true}
                                        />
                                    </Control>
                                </Field>
                            </Field>
                            <Field
                                className="is-medium"
                                isHorizontal={true}
                                htmlFor=""
                                label={translate('EngineerPage.Lable.2')}
                                labelSize={BulmaSize.Medium}
                                infoText=""
                            >
                                <Field>
                                    <Control>
                                        {!engId ? (
                                            <SelectField
                                                label=""
                                                value={user}
                                                isDisabled={isLoadingUsers}
                                                id="all-users-selector"
                                                options={newEngineers.map(x => ({
                                                    value: x.id,
                                                    label: x.firstName + ' ' + x.lastName,
                                                }))}
                                                handleChange={selectUser}
                                            />
                                        ) : (
                                            <TextField
                                                id="title"
                                                label=""
                                                value={
                                                    engineer &&
                                                    engineer.firstName + ' ' + engineer.lastName
                                                }
                                                isDisabled={true}
                                            />
                                        )}
                                    </Control>
                                </Field>
                            </Field>
                            <Field
                                className="is-medium"
                                isHorizontal={true}
                                htmlFor=""
                                label={translate('EngineerPage.Lable.3')}
                                labelSize={BulmaSize.Medium}
                                infoText=""
                            >
                                <Field>
                                    <Control>
                                        <div className="qualification-select">
                                            {qualificationCards.map(x => {
                                                return (
                                                    <Checkbox
                                                        checked={
                                                            selectedQualification &&
                                                            selectedQualification.length !== 0 &&
                                                            selectedQualification.some(s => s === x)
                                                        }
                                                        label={x}
                                                        id={x}
                                                        onChange={onToggleCheckList}
                                                        value={x}
                                                        key={x}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </Control>

                                    <Help
                                        isShown={
                                            selectedQualification &&
                                                selectedQualification.length !== 0
                                                ? false
                                                : true
                                        }
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {translate('EngineerPage.Lable.4')}
                                    </Help>
                                    {showWarning && (
                                        <p className="has-text-warning">
                                            {translate('EngineerPage.Lable.5')}
                                        </p>
                                    )}
                                </Field>
                            </Field>
                            <Toolbar type="save">
                                <ToolbarGroup>
                                    <Button buttonType="cancel" onClick={onCancel}>
                                        {translate('Globals.Label.Cancel')}
                                    </Button>
                                </ToolbarGroup>
                                <ToolbarGroupRight>
                                    {Object.keys(user).length !== 0 || engId === undefined ? (
                                        <Button
                                            disabled={
                                                selectedQualification &&
                                                    selectedQualification.length !== 0 &&
                                                    Object.keys(user).length !== 0
                                                    ? false
                                                    : true
                                            }
                                            type="submit"
                                            onClick={createNew}
                                        >
                                            {translate('EngineerPage.Lable.6')}
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={
                                                selectedQualification &&
                                                    selectedQualification.length !== 0
                                                    ? false
                                                    : true
                                            }
                                            className="is-pulled-right"
                                            type="submit"
                                            onClick={updateEngineer}
                                        >
                                            {translate('EngineerPage.Lable.7')}
                                        </Button>
                                    )}
                                </ToolbarGroupRight>
                            </Toolbar>
                        </div>
                    </div>
                </div>
            </Loader>
        </Page>
    );
};
