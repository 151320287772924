import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';

import { Epic, combineEpics } from 'redux-observable';
import { SkillCategoryActions } from 'actions/actions-v2';
import { ISkillCategory } from 'models';

const controllerName = 'skillCategory';

const createActions = {
    action: SkillCategoryActions.CREATE_SKILL_CATEGORY,
    actionFulfilled: SkillCategoryActions.CREATE_SKILL_CATEGORY_FULFILLED,
    actionRejected: SkillCategoryActions.CREATE_SKILL_CATEGORY_REJECTED,
} as IActionType;

const updateActions = {
    action: SkillCategoryActions.UPDATE_SKILL_CATEGORY,
    actionFulfilled: SkillCategoryActions.UPDATE_SKILL_CATEGORY_FULFILLED,
    actionRejected: SkillCategoryActions.UPDATE_SKILL_CATEGORY_REJECTED,
} as IActionType;

const getAllActions = {
    action: SkillCategoryActions.LOAD_SKILL_CATEGORIES,
    actionFulfilled: SkillCategoryActions.LOAD_SKILL_CATEGORIES_FULFILLED,
    actionRejected: SkillCategoryActions.LOAD_SKILL_CATEGORIES_REJECTED,
} as IActionType;

const getActions = {
    action: SkillCategoryActions.LOAD_SKILL_CATEGORY,
    actionFulfilled: SkillCategoryActions.LOAD_SKILL_CATEGORY_FULFILLED,
    actionRejected: SkillCategoryActions.LOAD_SKILL_CATEGORY_REJECTED,
} as IActionType;

const getQualificationCardsForSite = {
    action: SkillCategoryActions.SITE_QUALIFICATION_CARDS,
    actionFulfilled: SkillCategoryActions.SITE_QUALIFICATION_CARDS_FULFILLED,
    actionRejected: SkillCategoryActions.SITE_QUALIFICATION_CARDS_REJECTED,
} as IActionType;

const deleteActions = {} as IActionType;

const createSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/SkillCategory').post<ISkillCategory>(
        `/api/${controllerName}`
    );
const updateSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/SkillCategory').put<ISkillCategory>(
        `/api/${controllerName}`
    );
const loadSkillCategoriesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<ISkillCategory[]>(`/api/${controllerName}`);
const loadSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).get<ISkillCategory>(`/api/${controllerName}`);
const deleteSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).deleteById<string>(`/api/${controllerName}`);
const siteQualificationCardsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getQualificationCardsForSite).getById<string>(
        `/api/${controllerName}/qualification-cards`
    );

export const skillCategoryEpicsV2 = combineEpics(
    createSkillCategoryEpic,
    updateSkillCategoryEpic,
    loadSkillCategoriesEpic,
    loadSkillCategoryEpic,
    deleteSkillCategoryEpic,
    siteQualificationCardsEpic
);
