import * as React from 'react';
import useTranslate from 'translations/translation-utils';
import { TextField } from 'components/form/fields/text-field';
import { ICountryModel, ISkillCard, IVisibility, Visibility, getDefaultSkillCard, getVisibility } from 'models/competency-dna/skill-card';
import { IKeyValue } from 'models';
import { ModalCard, ModalCardHeader, ModalCardBody, ModalCardFooter } from 'components/modalCard';
import { Button } from 'components/v2/components';
import Select from 'react-select';
import { FilterSet } from 'components/filterSet';
import { ICommonLookupModel } from 'models/common-lookup-model';
import _ from 'lodash';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IGlobalSkillCardModel } from 'models/admin-cdna/global-skill-card-model';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { array, object, string } from 'yup';
import { Help } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';

export interface IProps {
    showDialogue: boolean;
    operatingPlatforms: IOperatingPlatform[];
    linesOfBusiness: ICommonLookupModel[];
    lookupCountryRegion: Array<IKeyValue<string>>;
    lookupCountry: Array<IKeyValue<string>>;
    regions: Array<IKeyValue<string>>;
    onClose: () => void;
    onSave: (skillCard: ISkillCard) => void;
    globalSkillCard?: IGlobalSkillCardModel;
    isLoading: boolean;
}

export const CompetencyDnaSkillCardDialogue: React.FC<IProps> = ({
    showDialogue,
    operatingPlatforms,
    linesOfBusiness,
    regions,
    lookupCountryRegion,
    lookupCountry,
    onClose,
    onSave,
    globalSkillCard,
    isLoading
}) => {
    const translate = useTranslate();

    let formikBag: FormikProps<Partial<ISkillCard>>;

    const formValidationSchema = object<Partial<ISkillCard>>().shape({
        name: string()
            .trim()
            .required(translate('CompetencyDNAProcessesAdmin.QualificationCard.Validation.Name'))
        ,
        visibility: object<Partial<IVisibility>>()
            .shape({
                operatingPlatforms: array()
                    .min(1, translate('CompetencyDNAProcessesAdmin.QualificationCard.Validation.OperatingPlatform'))
                    .nullable(false),
            }),
    });

    let countries: Array<ICountryModel> = [];

    const onHandleTextChange = async (e: any) => {
        e.preventDefault();
        const { value } = e.target;
        await formikBag.setFieldValue('name', value, true);
    }

    const handleInputChange = async (value: Array<IKeyValue<string>>, key: string) => {
        let visibility = getVisibility(value, key, formikBag.values.visibility, false);
        if (key === Visibility.OperatingPlatform) {
            visibility = filterLineOfBusiness(visibility);
        }
        await formikBag.setFieldValue('visibility', visibility, true);
    }

    const getLineOfBusinessOptions = () => {
        return linesOfBusiness &&
            linesOfBusiness
                .filter(f => formikBag.values.visibility.operatingPlatforms.find(i => i === f.operatingPlatformId))
                .map((x) => ({
                    value: x.key,
                    label: x.operatingPlatform + '-' + x.value,
                }))
    }

    const filterLineOfBusiness = (visibility: IVisibility) => {
        const availbaleLOB = linesOfBusiness &&
            linesOfBusiness
                .filter(f => visibility.operatingPlatforms.find(i => i === f.operatingPlatformId));

        return { ...visibility, linesOfBusiness: visibility.linesOfBusiness.filter(f => availbaleLOB.find(i => i.key === f)) };
    }

    const getCountriesByRegions = () => {
        const countryWithRegion: Array<ICountryModel> = lookupCountryRegion.filter((cr) => formikBag.values.visibility.regions.find(f => f === cr.value))
            .map(m => { return { region: m.value, key: m.key, value: m.value } });

        countries = countryWithRegion && countryWithRegion.length > 0 ?
            countryWithRegion.map((c) => {
                const countryByCode = lookupCountry.find(f => f.key === c.key);
                return { ...c, label: c.region + '-' + countryByCode.value }
            }) :
            [];

        return countries.map((x) => { return { label: x.label, value: x.key } });
    };

    const onHandleCancel = () => {
        onClose();
    }

    const getHelper = (formikErrors: FormikErrors<any> | any) => {
        return (
            <Help
                bulmaColor={BulmaColor.Danger}
                isShown={(formikErrors && formikErrors.length > 0)}
            >
                {formikErrors}
            </Help>
        );
    };

    const isValid = () => {
        return formikBag.values.id ? formikBag.isValid : formikBag.dirty ? Object.keys(formikBag.errors).length === 0 : false;
    }

    const renderModel = (formik: FormikProps<Partial<ISkillCard>>) => {
        return <>
            <ModalCard modalId="textUpdate" isShown={showDialogue}>
                <ModalCardHeader
                    modalId="confirmation"
                    title={translate(formik?.values?.id ?
                        'CompetencyDNAProcessesAdmin.QualificationCard.Label.Edit' :
                        'CompetencyDNAProcessesAdmin.QualificationCard.Label.Create')}
                />
                <ModalCardBody modalId="confirmation">
                    <TextField
                        id="QualificationCardType"
                        label={''}
                        value={formik.values.name}
                        handleChange={onHandleTextChange}
                        placeholder='Qualificaton Card Name'
                        readOnly={formik?.values?.id ? true : false}
                    />
                    {getHelper((formik.errors && formik.errors.name) ?? "")}
                    <div className="dialog-title is-5 section-title">{translate('CompetencyDNAProcessesAdmin.QualificationCard.Label.Visibility')}</div>
                    <FilterSet title={translate("Globals.Label.OperatingPlatform")} fieldName={"operatingPlatform"} >
                        <Select
                            id="operatingPlatform"
                            options={operatingPlatforms &&
                                operatingPlatforms.map((x) => ({
                                    value: x.id,
                                    label: x.value,
                                }))}
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'operatingPlatform')}
                            placeholder={translate('Globals.Label.Select')}
                            value={operatingPlatforms.filter(f => formik.values.visibility.operatingPlatforms.includes(f.id))
                                .map((x) => { return { label: x.value, value: x.id } })
                            }
                        />
                    </FilterSet>
                    {getHelper((formik.errors && formik.errors.visibility?.operatingPlatforms) ?? "")}
                    <FilterSet title={translate("Globals.Label.LineOfBusiness")} fieldName={"LineOfBusiness"} >
                        <Select
                            id="lineOfBusiness"
                            options={getLineOfBusinessOptions()}
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'lineOfBusiness')}
                            placeholder={translate('Globals.Label.Select')}
                            value={linesOfBusiness.filter(f => formik.values.visibility.linesOfBusiness.includes(f.key))
                                .map((x) => { return { label: x.operatingPlatform + '-' + x.value, value: x.key } })}
                            isDisabled={!formik.values.visibility.operatingPlatforms || formik.values.visibility.operatingPlatforms.length === 0}
                        />
                    </FilterSet>
                    <FilterSet title={translate("Globals.Label.Regions")} fieldName={"Regions"} >
                        <Select
                            id="regions"
                            options={regions && regions.map((x) => ({
                                value: x.value,
                                label: x.value,
                            }))}
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'regions')}
                            placeholder={translate('Globals.Label.Select')}
                            value={regions
                                .filter(f => formik.values.visibility.regions.includes(f.value))
                                .map((x) => { return { label: x.value, value: x.value } })}
                            isDisabled={!formik.values.visibility.operatingPlatforms || formik.values.visibility.operatingPlatforms.length === 0}
                        />
                    </FilterSet>
                    <FilterSet title={translate("Globals.Label.Country")} fieldName={"Country"} >
                        <Select
                            id="country"
                            options={getCountriesByRegions()}
                            isMulti={true}
                            onChange={(e: any) => handleInputChange(e, 'country')}
                            placeholder={translate('Globals.Label.Select')}
                            value={countries.filter(f => formik.values.visibility.countries.includes(f.key))
                                .map((x) => { return { label: x.label, value: x.key } })}
                            isDisabled={!formik.values.visibility.operatingPlatforms ||
                                formik.values.visibility.operatingPlatforms.length === 0 ||
                                !formik.values.visibility.regions ||
                                formik.values.visibility.regions.length === 0
                            }
                        />
                    </FilterSet>
                </ModalCardBody>
                <ModalCardFooter>
                    <Button
                        className={'is-pulled-right'}
                        onClick={() => formikBag.handleSubmit()}
                        disabled={!isValid() || isLoading}
                    >
                        {translate('Globals.Label.Save')}
                    </Button>
                    <Button onClick={() => onHandleCancel()} buttonType="cancel">
                        {translate('Globals.Label.Cancel')}
                    </Button>
                </ModalCardFooter>
            </ModalCard>
        </>
    }

    return (
        <>
            <Formik<Partial<ISkillCard>>
                initialValues={Object.assign({}, globalSkillCard ?? getDefaultSkillCard())}
                validationSchema={formValidationSchema}
                onSubmit={(values: ISkillCard) => onSave(values)}
                render={(formik) => {
                    formikBag = formik;
                    return renderModel(formik);
                }}
                enableReinitialize={true}
                isInitialValid={true}
                validateOnChange={true}
            />
        </>
    );
};