import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsUploading = (state: IRootState) => state.cermAssessmentResultState.isLoading;
const getIsUploadingHistory = (state: IRootState) =>
    state.cermAssessmentResultState.isLoadingHistory;
const getHistory = (state: IRootState) => state.cermAssessmentResultState.history;
const getSite = (state: IRootState) => state.siteState.site;

export default createSelector(
    getIsUploading,
    getIsUploadingHistory,
    getHistory,
    getSite,
    (isLoading, isLoadingHistory, history, site) => ({
        isLoading,
        isLoadingHistory,
        history,
        site,
    })
);
