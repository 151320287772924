import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';
import { Masthead } from 'components/masthead';
import { ScrollUpButton } from 'components/scrollUpButton';
import { SessionTimer } from 'components/session-timer/SessionTimer';
import { Toastr } from '../toastr';
import { SideNav } from 'components/sideNav';
import actions from './actions';
import { RefreshToken } from 'utils/refresh-token';
import withAppInsights from 'routes/AppInsight';

interface IProps extends RouteComponentProps {
    children: any;
    onInitialiseNav: () => void;
    loadOperatingPlatform: () => void;
    loadSiteMetaData: () => void;
    sendRequestSessionAlive: () => void;
}

const Layout: React.FC<IProps> = ({
    children,
    onInitialiseNav,
    loadOperatingPlatform,
    loadSiteMetaData,
    sendRequestSessionAlive,

}) => {
    React.useEffect(() => {
        onInitialiseNav();
        loadOperatingPlatform();
        loadSiteMetaData();
    }, []);

    return (
        <>
            <Masthead />
            <SessionTimer sendRequestSessionAlive={sendRequestSessionAlive} />
            <RefreshToken />
            <div className="main quantum">
                <SideNav />
                <div className="container">{children}</div>
            </div>
            <ScrollUpButton />
            <Toastr />
        </>
    );
};

export default withRouter(withAppInsights(connect(null, actions)(Layout)));
