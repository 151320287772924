import * as React from 'react';
import { ISkillCategory, IKeyValue } from 'models';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { IOption, SelectField } from 'components/form/fields/select-field';
import { TextField } from 'components/form/fields/text-field';
import { ModalCard, ModalCardHeader, ModalCardBody, ModalCardFooter } from 'components/modalCard';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import '../engineers/engineer.scss';

export interface IProps {
    onSaveUpdateSkillCategory?: (skillCategory: ISkillCategory) => void;
    onCancel?: () => void;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
    skillCategoryToEdit: ISkillCategory;
}

export const EditLocalSkillCategory: React.FC<IProps> = ({
    onSaveUpdateSkillCategory,
    onCancel,
    siteId,
    lookupSites,
    skillCategoryToEdit,
}) => {
    const translate = useTranslate();

    const [skillCategory, setSkillCategory] = React.useState<ISkillCategory>({
        id: skillCategoryToEdit.id,
        name: skillCategoryToEdit.name,
        description: skillCategoryToEdit.description,
        categoryNumber: skillCategoryToEdit.categoryNumber,
        isGlobal: skillCategoryToEdit.isGlobal,
        localSiteId: skillCategoryToEdit.localSiteId,
        allSitesSelected: skillCategoryToEdit.allSitesSelected,
        selectedSites: skillCategoryToEdit.selectedSites,
        code: skillCategoryToEdit.code,
        categoryId: skillCategoryToEdit.categoryId,
        categoryName: skillCategoryToEdit.categoryName,
        isActive: skillCategoryToEdit.isActive,
        qualificationCardType: skillCategoryToEdit.qualificationCardType,
        status: skillCategoryToEdit.status,
    });

    const initSelectedSites = (): any => {
        let result = [];
        if (skillCategory?.allSitesSelected && skillCategory?.allSitesSelected === true) {
            result = getChangedSite(lookupSites.map((x) => ({
                key: x.key,
                value: x.value,
                label: x.value,
            })));
        } else if (skillCategory?.selectedSites.length > 0) {
            result = getChangedSite(lookupSites.filter(x =>
                skillCategory?.selectedSites.findIndex(y => x.key == y) > -1
            ).map((x) => ({
                key: x.key,
                value: x.value,
                label: x.value,
            })));
        } else {
            result = getChangedSite(lookupSites.filter(x => x.key == siteId).map((x) => ({
                key: x.key,
                value: x.value,
                label: x.value,
            })));
        }
        return result;
    };

    const onChangeCategoryTitle = (field: any): void => {
        const skillUpdated: ISkillCategory = { ...skillCategory, name: field.currentTarget.value };
        setSkillCategory(skillUpdated);
    };

    const onChangeCategoryDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const skillUpdated: ISkillCategory = {
            ...skillCategory,
            description: event.currentTarget.value,
        };
        setSkillCategory(skillUpdated);
    };

    const onSave = () => onSaveUpdateSkillCategory(skillCategory);

    const checkAddingAndRemovingAllowedSites = (
        adding: Array<any>,
        removing: Array<IKeyValue<string>>
    ) => {
        return (
            adding &&
            adding.every((s: IKeyValue<string>) => lookupSites.some((ss) => ss.key === s.key)) &&
            removing &&
            removing.every((s: IKeyValue<string>) => lookupSites.some((ss) => ss.key === s.key))
        );
    };

    const updateSkillCategory = (skillCategory: ISkillCategory, selectedSites: any[]): void => {
        const updatedSkillCategory = { ...skillCategory, selectedSites: selectedSites?.map(item => item.key) };
        setSkillCategory(updatedSkillCategory);
    };

    const onSiteChange = (e: Array<any>) => {
        const options: Array<IKeyValue<string>> = lookupSites;
        let siteToAdd: IKeyValue<string>[] = [];
        let sitesToRemove: IKeyValue<string>[] = [];
        const index = e?.findIndex(x => x.key === 'All');
        if (index > -1) {
            siteToAdd = lookupSites;
            if (checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove)) {
                setSelSites(lookupSites.map((x) => ({
                    key: x.key,
                    value: x.value,
                    label: x.value,
                })));
            }
        } else {
            siteToAdd = e
                ? e.filter((m: IKeyValue<string>) => options && !options.some((s) => s.key === m.key))
                : options.filter((o: IKeyValue<string>) => o && e && e.some((s) => s.key === 'All'));
            sitesToRemove = options
                ? options.filter((m: IKeyValue<string>) =>
                    e ? !e.some((s: IKeyValue<string>) => s.key === m.key) : [])
                : [];
            if (!!sitesToRemove.find(x => x.key === siteId)) {
                sitesToRemove = sitesToRemove.filter(x => x.key !== siteId)
                const x = options.find(x => x.key === siteId);
                e.push({
                    key: x.key,
                    value: x.value,
                    label: x.value,
                });
            }
            if (checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove)) {
                if (e == null) {
                    setSelSites(lookupSites.filter(x => x.key === siteId).map((x) => ({
                        key: x.key,
                        value: x.value,
                        label: x.value,
                    })));
                }
                else {
                    setSelSites(e);
                }
            }
        }
    };

    const getChangedSite = (e: Array<any>): Array<any> => {
        let result = [];
        const options: Array<IKeyValue<string>> = lookupSites;
        let siteToAdd = [];
        let sitesToRemove = [];
        const index = e?.findIndex(x => x.key === 'All');
        if (index > -1) {
            siteToAdd = lookupSites;
            if (checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove)) {
                result = lookupSites.map((x) => ({
                    key: x.key,
                    value: x.value,
                    label: x.value,
                }));
            }
        } else {
            siteToAdd = e
                ? e.filter((m: IKeyValue<string>) => options && !options.some((s) => s.key === m.key))
                : options.filter((o: IKeyValue<string>) => o && e && e.some((s) => s.key === 'All'));
            sitesToRemove = options
                ? options.filter((m: IKeyValue<string>) =>
                    e ? !e.some((s: IKeyValue<string>) => s.key === m.key) : []
                )
                : [];
            result = checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove) ? e : [];
        }
        return result;
    };

    const [selSites, setSelSites] = React.useState(initSelectedSites());

    React.useEffect(() => {
        updateSkillCategory(skillCategory, selSites);
    }, [selSites]);

    const getSitesWithAllOption = (): IOption[] => {
        let s = [...lookupSites];
        s.splice(0, 0, {
            key: 'All',
            value: 'All',
            label: 'All',
        });

        return s.map((x) => ({
            key: x.key,
            value: x.value,
            label: x.value,
        }));
    };

    React.useEffect(() => {
        if (selSites?.length === lookupSites?.length) {
            skillCategory.allSitesSelected = true;
            skillCategory.selectedSites = [];
        } else {
            skillCategory.allSitesSelected = false;
            skillCategory.selectedSites = selSites.map((x) => {
                return x.key;
            });
        }
    }, [selSites]);

    const isReadyToSave: boolean =
        skillCategory.name.length > 0 &&
        skillCategory.description.length > 0 &&
        selSites.length > 0;

    return (
        <>
            <ModalCard modalId="textUpdate" isShown={true}>
                <ModalCardHeader modalId="confirmation" title={translate('CompetencyDnaProcess.Label.17')} />
                <ModalCardBody modalId="confirmation">
                    <TextField
                        id="name"
                        label={translate('AddLocalSkillCat.Label.2')}
                        value={skillCategory.name}
                        handleChange={onChangeCategoryTitle}
                    />
                    <TextAreaField
                        id="CategoryDescription"
                        label={translate('AddLocalSkillCat.Label.3')}
                        value={skillCategory.description}
                        handleChange={onChangeCategoryDescription}
                    />
                    <SelectField
                        id="roles-selector"
                        label={translate('AddLocalSkillCat.Label.5')}
                        options={getSitesWithAllOption()}
                        isMulti={true}
                        handleChange={onSiteChange}
                        value={selSites}
                        className={"display-none"}
                    />
                </ModalCardBody>
                <ModalCardFooter>
                    <Button
                        className={'is-pulled-right'}
                        onClick={onSave}
                        disabled={!isReadyToSave}
                    >
                        {translate('Globals.Label.Save')}
                    </Button>
                    <Button onClick={onCancel} buttonType="cancel">
                        {translate('Globals.Label.Cancel')}
                    </Button>
                </ModalCardFooter>
            </ModalCard>
        </>
    );
};
