import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IGlobalSkillCardModel, IHierarchicalPathsModel } from 'models/admin-cdna/global-skill-card-model';
import { GlobalSkillCategoryActions } from 'actions/global-skill-category-action';
import { GlobalSkillCardActions } from 'actions/global-skill-card-actions';
import { IGlobalSkillCategory } from 'models/competency-dna/cdna';
import { GlobalSkillActions } from 'actions/global-skill-actions';
import { IGlobalSkill } from 'models/admin-cdna/global-skill';

export interface IAdminCdnaGlobalState {
    globalSkillCards: IGlobalSkillCardModel[],
    globalSkillCategories: IGlobalSkillCategory[],
    globalSkills: IGlobalSkill[],
    isLoading: boolean;
    globalSkillCardsHierarchyPathsByVisiblity: IHierarchicalPathsModel[],
}

const INITIAL_STATE: IAdminCdnaGlobalState = {
    globalSkillCards: [],
    globalSkillCategories: [],
    globalSkills: [],
    isLoading: false,
    globalSkillCardsHierarchyPathsByVisiblity: [],
};

const globalSkillCards = (
    state: IAdminCdnaGlobalState['globalSkillCards'] = INITIAL_STATE.globalSkillCards,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_FULFILLED:
            return action.payload;
        case GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD_FULFILLED:
            return action.payload ?
                [...state, action.payload,] :
                state;
        case GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD_FULFILLED:
        case GlobalSkillCardActions.PUBLISH_SKILLCARD_FULFILLED:
            const index = state.findIndex((item) => item.id === action.payload?.id);
            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    action.payload,
                    ...state.slice(index + 1),
                ];
            }
            return state;
        case GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD_FULFILLED:
            return state.filter((item) => item.id !== action.payload.id);
        default:
            return state;
    }
};

const globalSkillCategories = (
    state: IAdminCdnaGlobalState['globalSkillCategories'] = INITIAL_STATE.globalSkillCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES_FULFILLED:
            return action.payload;
        case GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY_FULFILLED:
            return action.payload ?
                [...state, action.payload,] :
                state;
        case GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY_FULFILLED:
            const index = state.findIndex((item) => item.id === action.payload?.id);
            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    action.payload,
                    ...state.slice(index + 1),
                ];
            }
            return state;
        case GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY_FULFILLED:
            return state.filter((item) => item.id !== action.payload.id);
        default:
            return state;
    }
};

const globalSkills = (
    state: IAdminCdnaGlobalState['globalSkills'] = INITIAL_STATE.globalSkills,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY_FULFILLED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD_FULFILLED:
            return action.payload;
        case GlobalSkillActions.CREATE_GLOBAL_SKILL_FULFILLED:
            return [...state, action.payload,];
        case GlobalSkillActions.UPDATE_GLOBAL_SKILL_FULFILLED:
            const index = state.findIndex((item) => item.id === action.payload.id);
            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    action.payload,
                    ...state.slice(index + 1),
                ];
            }
            return state;
        case GlobalSkillActions.DELETE_GLOBAL_SKILL_FULFILLED:
            return state.filter((item) => item.id !== action.payload.id);
        default:
            return state;
    }
};

const isLoading = (
    state: IAdminCdnaGlobalState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD:
        case GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD:
        case GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD:
        case GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD:
        case GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES:
        case GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY:
        case GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY:
        case GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY:
        case GlobalSkillActions.LOAD_GLOBAL_SKILL:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD:
        case GlobalSkillActions.CREATE_GLOBAL_SKILL:
        case GlobalSkillActions.UPDATE_GLOBAL_SKILL:
        case GlobalSkillActions.DELETE_GLOBAL_SKILL:
        case GlobalSkillCardActions.PUBLISH_SKILLCARD:
            return true;
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_FULFILLED:
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_REJECTED:
        case GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD_FULFILLED:
        case GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD_REJECTED:
        case GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD_FULFILLED:
        case GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD_REJECTED:
        case GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD_FULFILLED:
        case GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD_REJECTED:
        case GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES_FULFILLED:
        case GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES_REJECTED:
        case GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY_FULFILLED:
        case GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY_REJECTED:
        case GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY_FULFILLED:
        case GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY_REJECTED:
        case GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY_FULFILLED:
        case GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY_REJECTED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILL_FULFILLED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILL_REJECTED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY_FULFILLED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY_REJECTED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD_FULFILLED:
        case GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD_REJECTED:
        case GlobalSkillActions.CREATE_GLOBAL_SKILL_FULFILLED:
        case GlobalSkillActions.CREATE_GLOBAL_SKILL_REJECTED:
        case GlobalSkillActions.UPDATE_GLOBAL_SKILL_FULFILLED:
        case GlobalSkillActions.UPDATE_GLOBAL_SKILL_REJECTED:
        case GlobalSkillActions.DELETE_GLOBAL_SKILL_FULFILLED:
        case GlobalSkillActions.DELETE_GLOBAL_SKILL_REJECTED:
        case GlobalSkillCardActions.PUBLISH_SKILLCARD_FULFILLED:
        case GlobalSkillCardActions.PUBLISH_SKILLCARD_REJECTED:
            return false;
        default:
            return state;
    }
};


const globalSkillCardsHierarchyPathsByVisiblity = (
    state: IAdminCdnaGlobalState['globalSkillCardsHierarchyPathsByVisiblity'] = INITIAL_STATE.globalSkillCardsHierarchyPathsByVisiblity,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY:
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_REJECTED:
        case GlobalSkillCardActions.RESET_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY:
            return INITIAL_STATE.globalSkillCardsHierarchyPathsByVisiblity;
        case GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

export const adminCdnaGlobalReducer = combineReducers<IAdminCdnaGlobalState>({
    globalSkillCards,
    globalSkillCategories,
    globalSkills,
    isLoading,
    globalSkillCardsHierarchyPathsByVisiblity
});