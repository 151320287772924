import { IOption } from 'components/form/fields/select-field';
import { ISkillCategory } from './engineer';

export interface IShift {
    id: string;
    siteId: string;
    name: string;
    engineers: IShiftEngineer[];
    status?: string;
    skillCategory?: ISkillCategory[];
}

export interface IShiftEngineer {
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    skills?: IShiftEngineerSkill[];
    qualificationCardTypes?: string[];
}

export const getOption = (engineer: IShiftEngineer): IOption => {
    if (engineer === null) {
        return null;
    }

    return {
        value: engineer.id,
        label: `${engineer.firstName} ${engineer.lastName}`,
    };
};

export interface ICreateShift {
    siteId: string;
    name: string;
    engineers: IShiftEngineer[];
}

export interface IShiftEngineerSkill {
    categoryId: string;
    categoryName: string;
    level: string;
}

export interface IShiftFilters {
    siteId: string;
}

export interface IShiftParticipant {
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    key: string;
    value: string;
    label: string;
}
