import { IKeyValue } from 'models/key-value';

export interface ISkillCard {
    id: string;
    name: string;
    visibility: IVisibility;
}

export interface IVisibility {
    operatingPlatforms: string[];
    linesOfBusiness: string[];
    regions: string[];
    countries: string[];
}

export const getDefaultSkillCard = () => {
    return {
        id: null,
        name: '',
        visibility: {
            operatingPlatforms: [],
            linesOfBusiness: [],
            regions: [],
            countries: []
        },
    }
}

export interface ICountryModel {
    region: string;
    key: string;
    value: string;
    label?: string;
}

const setRegionVisibility = (
    value: Array<IKeyValue<string>>,
    selectedVisibility: IVisibility,
) => {
    let visibility: IVisibility = { ...selectedVisibility };
    if (!value) {
        visibility.regions = [];
        visibility.countries = [];
    }
    else {
        visibility.regions = value.map(m => m.value);
    }

    return visibility;
}

export const setOperatingPlatformVisibility = (
    value: Array<IKeyValue<string>>,
    selectedVisibility: IVisibility,
    isFilter: boolean,
) => {
    let visibility: IVisibility = { ...selectedVisibility };

    if (isFilter) {
        visibility.operatingPlatforms = value ? value.map(m => m.value) : [];
        return visibility;
    }
    if (!value) {
        visibility = Object.assign({}, getDefaultSkillCard().visibility);
    }
    else {
        visibility.operatingPlatforms = value.map(m => m.value);
    }

    return visibility;
}

export const getVisibility = (
    value: Array<IKeyValue<string>>,
    key: string,
    selectedVisibility: IVisibility,
    isFilter: boolean,
) => {
    let visibility: IVisibility = { ...selectedVisibility };

    switch (key) {
        case 'operatingPlatform':
            visibility = setOperatingPlatformVisibility(value, selectedVisibility, isFilter);
            break;
        case 'lineOfBusiness':
            visibility.linesOfBusiness = value ? value.map(m => m.value) : [];
            break;
        case 'regions':
            visibility = setRegionVisibility(value, selectedVisibility);
            break;
        case 'country':
            visibility.countries = value ? value.map(m => m.value) : [];
            break;
        default:
            break;
    }

    return visibility;
}

export enum Visibility {
    OperatingPlatform = 'operatingPlatform',
    LineOfBusiness = 'lineOfBusiness',
    Regions = 'regions',
    Country = 'country',
}
