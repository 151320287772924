import * as React from 'react';
import {
    ISkill,
    engineerSkillLevels,
    SkillLevel,
    ISkillCategory,
    QualificationType,
    IKeyValue,
} from 'models';
import { newGuid } from 'utils/id-utils';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { IOption, SelectField } from 'components/form/fields/select-field';
import { TextField } from 'components/form/fields/text-field';
import { ModalCard, ModalCardHeader, ModalCardBody, ModalCardFooter } from 'components/modalCard';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import '../engineer/engineer.scss';

export interface IProps {
    // global skills only used to check ref numbers not used already
    globalSkills?: ISkill[];
    localSkills?: ISkill[];
    allSkillCategories: ISkillCategory[];
    qualificationCardTypes: string;
    onSaveLocalSkillCategory?: (skill: ISkill, skillCategory: ISkillCategory) => void;
    onCancel?: () => void;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
}

export const AddLocalSkillCategory: React.FC<IProps> = ({
    globalSkills,
    localSkills,
    qualificationCardTypes,
    allSkillCategories,
    onSaveLocalSkillCategory,
    onCancel,
    siteId,
    lookupSites,
}) => {

    const translate = useTranslate();

    const getNewCategory = () => {
        return allSkillCategories.length + 1;
    };

    const [skillCategory, setSkillCategory] = React.useState<ISkillCategory>({
        id: '',
        name: '',
        description: '',
        categoryNumber: getNewCategory(),
        isGlobal: false,
        localSiteId: siteId,
        allSitesSelected: false,
        selectedSites: [],
        code: '',
    });

    const initSelectedSites = (): any => {
        let result = [];
        if (skillCategory?.allSitesSelected && skillCategory?.allSitesSelected === true) {
            result = getChangedSite(lookupSites.map((x) => ({
                key: x.key,
                value: x.value,
                label: x.value,
            })));
        } else if (skillCategory?.selectedSites.length > 0) {
            result = getChangedSite(lookupSites.filter(x =>
                skillCategory?.selectedSites.findIndex(y => x.key == y) > -1
            ).map((x) => ({
                key: x.key,
                value: x.value,
                label: x.value,
            })));
        } else {
            result = getChangedSite(lookupSites.filter(x => x.key == siteId).map((x) => ({
                key: x.key,
                value: x.value,
                label: x.value,
            })));
        }
        return result;
    };

    const getNextSkillNumber = (skillCategoryId: string): number => {
        const skillsInCategory = globalSkills
            .concat(localSkills)
            .filter((x) => x.skillCategoryId === skillCategoryId);
        if (skillsInCategory.length !== 0) {
            const skillNumber: number =
                Math.max.apply(
                    null,
                    skillsInCategory.map((x) => x.skillNumber)
                ) + 1;
            return skillNumber;
        } else {
            return 1;
        }
    };

    const getNewSkill = () => {
        return {
            id: newGuid(),
            skillLevel: null,
            skillCategoryId: skillCategory.id,
            skillNumber: getNextSkillNumber(skillCategory.id),
            description: '',
        } as ISkill;
    };

    const [skill, setSkill] = React.useState<ISkill>(getNewSkill());

    const onChangeSkillLevel = (skillLevel: any) => {
        const sk = Array.isArray(skillLevel) ? skillLevel[0].value : skillLevel.value;
        const skillUpdated: ISkill = { ...skill, skillLevel: SkillLevel[sk] };
        setSkill(skillUpdated);
    };

    const onChangeCategoryQualificationCard = (field: any): void => {
        const skillUpdated: ISkillCategory = {
            ...skillCategory,
            qualificationCardType: field.currentTarget.value,
        };
        setSkillCategory(skillUpdated);
    };

    const onChangeCategoryTitle = (field: any): void => {
        const skillUpdated: ISkillCategory = { ...skillCategory, name: field.currentTarget.value };
        setSkillCategory(skillUpdated);
    };

    const onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const skillUpdated: ISkill = { ...skill, description: event.currentTarget.value };
        setSkill(skillUpdated);
    };

    const onChangeCategoryDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const skillUpdated: ISkillCategory = {
            ...skillCategory,
            description: event.currentTarget.value,
        };
        setSkillCategory(skillUpdated);
    };

    const onSave = () => {
        const prefix = skillCategory.selectedSites.length === 1 ? 'L' : 'A';
        skillCategory.id = newGuid();
        skillCategory.code =
            qualificationCardTypes === QualificationType.ME
                ? `${prefix}QS${getNewCategory()}`
                : `${prefix}ITQS-${getNewCategory()}`;
        skillCategory.qualificationCardType =
            (qualificationCardTypes ?? skillCategory.qualificationCardType).toUpperCase();
        skillCategory.selectedSites = selSites?.map(item => item.key);
        skill.skillCategoryId = skillCategory.id;
        skill.isAccount = skillCategory?.selectedSites?.length !== 1;
        onSaveLocalSkillCategory(skill, skillCategory);
    };

    const checkAddingAndRemovingAllowedSites = (
        adding: Array<any>,
        removing: Array<IKeyValue<string>>
    ) => {
        return (
            adding &&
            adding.every((s: IKeyValue<string>) => lookupSites.some((ss) => ss.key === s.key)) &&
            removing &&
            removing.every((s: IKeyValue<string>) => lookupSites.some((ss) => ss.key === s.key))
        );
    };

    const onSiteChange = (e: Array<any>) => {
        const options: Array<IKeyValue<string>> = lookupSites;
        let siteToAdd: IKeyValue<string>[] = [];
        let sitesToRemove: IKeyValue<string>[] = [];

        const index = e?.findIndex(x => x.key === 'All')
        if (index > -1) {
            siteToAdd = lookupSites;
            if (checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove)) {
                setSelSites(lookupSites.map((x) => ({
                    key: x.key,
                    value: x.value,
                    label: x.value,
                })));
            }
        } else {
            siteToAdd = e
                ? e.filter((m: IKeyValue<string>) => options && !options.some((s) => s.key === m.key))
                : options.filter((o: IKeyValue<string>) => o && e && e.some((s) => s.key === 'All'));
            sitesToRemove = options
                ? options.filter((m: IKeyValue<string>) =>
                    e ? !e.some((s: IKeyValue<string>) => s.key === m.key) : [])
                : [];
            if (!!sitesToRemove.find(x => x.key === siteId)) {
                sitesToRemove = sitesToRemove.filter(x => x.key !== siteId);
                const x = options.find(x => x.key === siteId);
                e.push({
                    key: x.key,
                    value: x.value,
                    label: x.value,
                });
            }
            if (checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove)) {
                if (e == null) {
                    setSelSites(lookupSites.filter(x => x.key === siteId).map((x) => ({
                        key: x.key,
                        value: x.value,
                        label: x.value,
                    })))
                } else {
                    setSelSites(e);
                }
            }
        }
    };

    const getChangedSite = (e: Array<any>): Array<any> => {
        let result = [];
        const options: Array<IKeyValue<string>> = lookupSites;
        let siteToAdd = [];
        let sitesToRemove = [];
        const index = e?.findIndex(x => x.key === 'All');
        if (index > -1) {
            siteToAdd = lookupSites;
            if (checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove)) {
                result = lookupSites.map((x) => ({
                    key: x.key,
                    value: x.value,
                    label: x.value,
                }));
            }
        } else {
            siteToAdd = e
                ? e.filter((m: IKeyValue<string>) => options && !options.some((s) => s.key === m.key))
                : options.filter((o: IKeyValue<string>) => o && e && e.some((s) => s.key === 'All'));
            sitesToRemove = options
                ? options.filter((m: IKeyValue<string>) =>
                    e ? !e.some((s: IKeyValue<string>) => s.key === m.key) : []
                )
                : [];
            result = checkAddingAndRemovingAllowedSites(siteToAdd, sitesToRemove) ? e : [];
        }
        return result;
    };

    const [selSites, setSelSites] = React.useState(initSelectedSites());

    const getSitesWithAllOption = (): IOption[] => {
        const s = [...lookupSites];
        s.splice(0, 0, {
            key: 'All',
            value: 'All',
            label: 'All',
        });

        return s.map((x) => ({
            key: x.key,
            value: x.value,
            label: x.value,
        }));
    };

    React.useEffect(() => {
        if (selSites?.length === lookupSites?.length) {
            skillCategory.allSitesSelected = true;
            skillCategory.selectedSites = [];
        } else {
            skillCategory.allSitesSelected = false;
            skillCategory.selectedSites = selSites.map(x => x.key);
        }
    }, [selSites]);

    const isReadyToSave: boolean =
        skill.skillLevel &&
        skill.description.length > 0 &&
        skillCategory.name.length > 0 &&
        (qualificationCardTypes ?? skillCategory.qualificationCardType).length > 0 &&
        skillCategory.description.length > 0 &&
        selSites.length > 0;

    return (
        <>
            <ModalCard modalId="textUpdate" isShown={true}>
                <ModalCardHeader modalId="confirmation" title={translate('AddLocalSkillCat.Title')} />
                <ModalCardBody modalId="confirmation">
                    {!qualificationCardTypes && (
                        <TextField
                            id="QualificationCardType"
                            label={translate('AddLocalSkillCat.Label.1')}
                            value={skillCategory.qualificationCardType}
                            handleChange={onChangeCategoryQualificationCard}
                        />
                    )}
                    <TextField
                        id="Title"
                        label={translate('AddLocalSkillCat.Label.2')}
                        value={skillCategory.name}
                        handleChange={onChangeCategoryTitle}
                    />
                    <TextAreaField
                        id="CategoryDescription"
                        label={translate('AddLocalSkillCat.Label.3')}
                        value={skillCategory.description}
                        handleChange={onChangeCategoryDescription}
                    />
                    <SelectField
                        id="selectSkillLevel"
                        label={translate('RiskRegisterPage.RiskFilter.Title.Level')}
                        value={{
                            value: skill.skillLevel,
                            label: skill.skillLevel,
                        }}
                        handleChange={onChangeSkillLevel}
                        options={engineerSkillLevels.map((x) => ({
                            value: x.key,
                            label: x.value,
                        }))}
                    />
                    {/* Description */}
                    <TextAreaField
                        id="LocacompetencyDescription"
                        label={translate('AddLocalSkillCat.Label.4')}
                        value={skill.description}
                        handleChange={onChangeDescription}
                    />
                    <SelectField
                        id="roles-selector"
                        label={translate('AddLocalSkillCat.Label.5')}
                        options={getSitesWithAllOption()}
                        isMulti={true}
                        handleChange={onSiteChange}
                        value={selSites}
                        className={"display-none"}
                    />
                </ModalCardBody>
                <ModalCardFooter>
                    <Button
                        className={'is-pulled-right'}
                        onClick={onSave}
                        disabled={!isReadyToSave}
                    >
                        {translate('Globals.Label.Save')}
                    </Button>
                    <Button onClick={onCancel} buttonType="cancel">
                        {translate('Globals.Label.Cancel')}
                    </Button>
                </ModalCardFooter>
            </ModalCard>
        </>
    );
};
