import { ISkillCard } from 'models/competency-dna/skill-card';
import { IAppAction } from './app-action';
import { IGlobalSkillCardQuery } from 'models/admin-cdna/global-skill-card-query';

export enum GlobalSkillCardActions {
    LOAD_GLOBAL_SKILL_CARD = '@@skillCard/LOAD_GLOBAL_SKILL_CARD',
    LOAD_GLOBAL_SKILL_CARD_FULFILLED = '@@skillCard/LOAD_GLOBAL_SKILL_CARD_FULFILLED',
    LOAD_GLOBAL_SKILL_CARD_REJECTED = '@@skillCard/LOAD_GLOBAL_SKILL_CARD_REJECTED',

    CREATE_GLOBAL_SKILL_CARD = '@@skillCard/CREATE_GLOBAL_SKILL_CARD',
    CREATE_GLOBAL_SKILL_CARD_FULFILLED = '@@skillCard/CREATE_GLOBAL_SKILL_CARD_FULFILLED',
    CREATE_GLOBAL_SKILL_CARD_REJECTED = '@@skillCard/CREATE_GLOBAL_SKILL_CARD_REJECTED',

    UPDATE_GLOBAL_SKILL_CARD = '@@skillCard/UPDATE_GLOBAL_SKILL_CARD',
    UPDATE_GLOBAL_SKILL_CARD_FULFILLED = '@@skillCard/UPDATE_GLOBAL_SKILL_CARD_FULFILLED',
    UPDATE_GLOBAL_SKILL_CARD_REJECTED = '@@skillCard/UPDATE_GLOBAL_SKILL_CARD_REJECTED',

    DELETE_GLOBAL_SKILL_CARD = '@@skillCard/DELETE_GLOBAL_SKILL_CARD',
    DELETE_GLOBAL_SKILL_CARD_FULFILLED = '@@skillCard/DELETE_GLOBAL_SKILL_CARD_FULFILLED',
    DELETE_GLOBAL_SKILL_CARD_REJECTED = '@@skillCard/DELETE_GLOBAL_SKILL_CARD_REJECTED',

    PUBLISH_SKILLCARD = '@@skillCard/PUBLISH_SKILLCARD',
    PUBLISH_SKILLCARD_FULFILLED = '@@skillCard/PUBLISH_SKILLCARD_FULFILLED',
    PUBLISH_SKILLCARD_REJECTED = '@@skillCard/PUBLISH_SKILLCARD_REJECTED',

    RESET_PUBLISH_SKILLCARD_FLAG = '@@skillCard/RESET_PUBLISH_SKILLCARD_FLAG',

    LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY = '@@skillCard/LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY',
    LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_FULFILLED = '@@skillCard/LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_FULFILLED',
    LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_REJECTED = '@@skillCard/LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_REJECTED',

    RESET_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY = '@@skillCard/RESET_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY',
}

export const loadGlobalSkillCards = (query: IGlobalSkillCardQuery): IAppAction => ({
    type: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD,
    payload: query,
});

export const createGlobalSkillCard = (skillCard: ISkillCard): IAppAction => ({
    type: GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD,
    payload: skillCard,
});

export const updateGlobalSkillCard = (skillCard: ISkillCard): IAppAction => ({
    type: GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD,
    payload: skillCard,
});

export const deleteGlobalSkillCard = (id: string): IAppAction => ({
    type: GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD,
    payload: id,
});

export const publishSkillCard = (skillCardId: string): IAppAction => ({
    type: GlobalSkillCardActions.PUBLISH_SKILLCARD,
    payload: { CardId: skillCardId },
});

export const loadGlobalSkillCardsHierarchyPathsByVisiblity = (query: IGlobalSkillCardQuery): IAppAction => ({
    type: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY,
    payload: query,
});

export const ResetGlobalSkillCardsHierarchyPathsByVisiblity = (): IAppAction => ({
    type: GlobalSkillCardActions.RESET_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY,
});