import { ISkill, ISiteSkills } from 'models';

export interface ISkillsState {
    skills: ISkill[];
    siteSkills: ISiteSkills;
    siteId: string;
    expandedCategories: string[];
    isLoading: boolean;
    isExportingSkills: boolean;
    isSaving: boolean;
    qualificationCards: string[];
}

const defaultState: ISkillsState = {
    skills: [],
    siteSkills: null,
    siteId: '',
    expandedCategories: ['QS1', 'QS2', 'QS3', 'QS4', 'QS5', 'QS6', 'QS7'],
    isLoading: false,
    isExportingSkills: false,
    isSaving: false,
    qualificationCards: [],
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultSkillsState = (options?: any) => {
    return { ...defaultState, ...options };
};
